import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { ThemeService } from './theme.service';
import { AuthService } from './auth.service';
import { ReplaySubject } from 'rxjs';
import { environment } from '@env';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class AffiliateService {
  _aconfig: any = (<any>window)._aconfig || environment.DEV?.ACONFIG;
  _referralCode: string = null;
  _affiliateCode: string = null;
  _affiliateCodeId: number = null;
  _affiliateCompanyId: number = null;
  _affiliateTheme: string = null;
  _affiliateTextIntro: string = null;

  _payoutPercentage: string = null;
  _payoutFixed: string = null;
  _payoutText: string = null;

  onAffiliateChange: ReplaySubject<AffiliateService> = new ReplaySubject(1);

  constructor(
    private restService: RestService,
    private themeService: ThemeService,
    private authService: AuthService,
  ) {
    this.initAConfig();
  }

  get config() {
    return this._aconfig || {};
  }

  get payoutPercentage() {
    const percent = Number((this._payoutPercentage || "90%").replace("%", "").replace(" ", ""));
    if(isNaN(percent)) {
      return 90;
    }

    return percent || 90;
  }

  get payoutText() {
    return this._payoutText || `${this.payoutFixed}€`;
  }

  get introText() {
    return this._affiliateTextIntro;
  }

  get payoutFixed() {
    const fixed = Number((this._payoutFixed || "360€").replace("€", "").replace(" ", ""));
    if(isNaN(fixed)) {
      return 360;
    }

    return fixed || 360;
  }

  initAConfig() {
    if (!this._aconfig) {
      return;
    }

    this.themeService.initTheme(this._aconfig || {});

    this.themeService.images = {
      ...this.themeService.images,
      ...this._aconfig.images
    };

    if (this._aconfig.affiliate_company) {
      this._affiliateCompanyId = this._aconfig.affiliate_company.id || null;
    }

    if (this._aconfig.affiliate_code) {
      this._affiliateCodeId = this._aconfig.affiliate_code.id || null;
      this._affiliateCode = this._aconfig.affiliate_code.code || null;
      this.authService.affiliateCode = this._affiliateCode;
      this.onAffiliateChange.next();
    }
  }

  getCompanyId() {
    return this._affiliateCompanyId;
  }

  getCodeId() {
    return this._affiliateCodeId;
  }

  getReferralCode() {
    return this._referralCode;
  }

  getAffiliateCode() {
    return this._affiliateCode;
  }

  isValid() {
    return this._affiliateCodeId != null;
  }

  setAffiliate(affiliate_code: string) {
    ////console.log("affiliate_code", affiliate_code);
    this._affiliateCode = affiliate_code;
    if (affiliate_code) {
      this.restService.getAffiliateDetails(affiliate_code).subscribe((data: any) => {
        if (data) {
          ////console.log("Code Data", data);

          this._payoutPercentage = data?.payout_percentage || null;
          this._payoutFixed = data?.payout_fixed || null;
          this._payoutText = data?.payout_text || null;
          this._affiliateTextIntro = data?.text_intro || null;

          this._affiliateCodeId = data.id || null;
          this.authService.affiliateCode = affiliate_code;
          this.onAffiliateChange.next();

          if(this._affiliateTextIntro) {
            ////console.log("I am here");
            $("#affiliatePopupModal").modal("show");
          }
        }
      });
    }
  }

  setReferral(referralCode: string) {
    this._referralCode = referralCode;
    this.authService.referralCode = referralCode;
  }

}