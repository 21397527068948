import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  errorMsg: string = ""

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  code: string = "";
  userId: string = "";
  username: string = null;

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.code = params.code;
        this.userId = params.id;
        this.username = params.email;
      });
  }

  register() {

  }

  setPw(pw1, pw2) {
    if (pw1.length < 8) {
      this.errorMsg = "Ihr Passwort muss aus mindestens 8 Zeichen bestehen!";
      return;
    }

    if (pw1 !== pw2) {
      this.errorMsg = "Ihre Passwörter stimmen nicht überein!";
      return;
    }

    this.auth.secureLogin.setPw(pw1, this.code, this.userId)
      .then(() => {
        this.router.navigate(["/login"], {
          queryParamsHandling: 'merge',
          queryParams: { id: null, code: null },
        });
        setTimeout(() => {
          window.location.reload();
        }, 100);
      })
      .catch(() => {
        this.errorMsg = "Dieser Passwort-Zurücksetzen Link ist ungültig.";
      });
  }
}
