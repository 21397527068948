import { Component, OnInit } from '@angular/core';
import { RestService } from '@autocash-core';

declare var $: any;

@Component({
  selector: 'app-treepage',
  templateUrl: './treepage.component.html',
  styleUrls: ['./treepage.component.scss']
})
export class TreepageComponent implements OnInit {
  climateHeroes = [];
  climateSavers = [];

  constructor(
    private rest: RestService
  ) { }

  ngOnInit(): void {
    const compare = (a, b) => {
      return (b?.trees || 0) -(a?.trees || 0)
    }

    this.rest.donations().subscribe((data: any) => {
      const { users } = data;
      if(!users?.length) {
        return;
      }

      users.forEach(el => {
        if(el?.trees >= 20) {
          this.climateHeroes.push(el);
          return;
        }

        this.climateSavers.push(el);
      });

      this.climateHeroes.sort(compare);
      this.climateSavers.sort(compare);
    });
  }

  scrollTo(id: string) {
    $(id)[0]?.scrollIntoView();
  }
}
