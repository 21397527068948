import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-agb',
  templateUrl: './agb.component.html',
  styleUrls: ['./agb.component.scss']
})
export class AgbComponent implements OnInit {
  @Input() show = false;

  env = environment;
  sub: Subscription;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.sub = this.activatedRoute.data.subscribe((data) => {
      if ("show" in data) {
        this.show = data.show;
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
