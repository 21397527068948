import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-charging-station',
  templateUrl: './charging-station.component.html',
  styleUrls: ['./charging-station.component.scss']
})
export class ChargingStationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  scrollTo(id: string) {
    $(id)[0]?.scrollIntoView();
  }
}
