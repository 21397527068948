import { Component, OnInit } from '@angular/core';
import { CmsReaderService } from '@autocash-core';

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.scss']
})
export class DatenschutzComponent implements OnInit {
  template: string = "";

  constructor(
    private cmsService: CmsReaderService
  ) { }

  ngOnInit(): void {
    this.cmsService.afterInit.subscribe(_ => {
      const html = this.cmsService.getCustomPageData("datenschutz").html || ""
      this.template = html;
    });
  }
}
