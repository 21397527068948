import { Component, OnInit } from '@angular/core';
import { CmsReaderService } from '@autocash-core';

@Component({
  selector: 'app-custom-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  name: string = "";
  email: string = "";
  phone: string = "";
  footer: string = "";

  address: any = {};

  constructor(
    private cmsService: CmsReaderService
  ) { }

  ngOnInit(): void {
    this.cmsService.afterInit.subscribe(_ => {
      const { name, email, phone_text, address, footer } = this.cmsService.companyData;

      this.name = name;
      this.email = email;
      this.phone = phone_text;

      this.address = address;
      this.footer = footer;
    });
  }
}
