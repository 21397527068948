import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';

import { v4 as uuidv4 } from 'uuid';
import { CmsService } from '../../services/cms.service';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit {
  formData: any[] = []

  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor(
    private cms: CmsService
  ) { }

  ngOnInit(): void {
    this.cms.afterInit.subscribe(_ => {
      if(this.cms.formData.length) {
        this.formData = this.cms.formData;
        return;
      }
      this.onAdd();
    });
  }

  onChange() {
    this.formData = this.formData.map(d => {
      d.valid = !!d.label;
      return d;
    });

    this.cms.formData = this.formData;
    this.change.emit(this.formData);
  }

  onAdd() {
    this.formData = [...this.formData, {
      id: uuidv4(),
      label: "",
      type: "text",
      valid: false
    }];

    this.cms.formData = this.formData;
    this.change.emit(this.formData);
  }

  onRemove(id: string) {
    this.formData = this.formData.filter(d => d.id !== id);

    this.cms.formData = this.formData;
    this.change.emit(this.formData);
  }
}
