import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { RestService } from '../../services/rest.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  error: boolean = false;
  sent: boolean = false;

  contactForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    subject: new FormControl("", [Validators.required]),
    message: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required, Validators.email])
  });

  constructor(
    private rest: RestService,
    private note: NotificationService
  ) { }

  ngOnInit(): void {
  }

  get name() {
    return this.contactForm.get("name");
  }

  get subject() {
    return this.contactForm.get("subject");
  }

  get message() {
    return this.contactForm.get("message");
  }

  get email() {
    return this.contactForm.get("email");
  }

  send() {
    this.sent = true;
    this.error = false;

    this.rest.contact({
      name: this.name.value,
      email: this.email.value,
      message: this.message.value,
      subject: this.subject.value
    }).subscribe(_ => {
        this.sent = true;
        this.error = false;
    }, _ => {
        this.error = true;
        this.note.error("Beim Senden der Nachricht ist ein Fehler aufgeteten")
    });
  }
}
