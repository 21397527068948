import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { CustomService } from '../../services/custom.service';
import { CustomComponentDirective } from '../directives/custom-component.directive';

@Component({
  selector: 'app-custom-container',
  templateUrl: './custom-container.component.html',
})
export class CustomContainerComponent implements OnInit {
  @Input() id: string = "";
  hasComponent: boolean = false;

  @ViewChild(CustomComponentDirective, {static: true}) componentHost: CustomComponentDirective;

  constructor(
    private customService: CustomService,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  ngOnInit(): void {
    const component = this.customService.components[this.id] || null;
    if(!component) {
      return;
    }

    this.hasComponent = true;
    this.loadComponent(component);
  }

  loadComponent(component: any) {
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const viewContainerRef = this.componentHost.viewContainerRef;
    viewContainerRef.clear();

    return viewContainerRef.createComponent(componentFactory);
  }
}
