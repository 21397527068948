import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  _type: string = 'single';

  get type() {
    return this._type;
  }

  constructor(
    private storageService: StorageService
  ) {
    this.storageService.session.setItem("client", "single");
  }

  setType(type: string) {
    this.storageService.session.setItem("client", type);
    this._type = type;
  }

  switchType() {
    switch (this._type) {
      case "single":
      case "social":
        this.setType("fleet");
        break;
      case "fleet":
        this.setType("single")
        break;
    }
  }

  get isSingle() {
    return this._type === "single";
  }
}
