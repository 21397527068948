import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomService {

  _components: any = {};
  get components() {
    return this._components;
  }

  constructor() { }

  register(id: string, cmp: any) {
    this._components[id] = cmp;
  }
}
