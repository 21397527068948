import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  _base = environment.BACK_URL;
  _pageData = {};

  _logo: any = {};
  set logo(data: any) {
    this._logo = data;
  }

  get logo() {
    return this._logo;
  }

  _favIcon: any = {};
  set favIcon(data: any) {
    this._favIcon = data;
  }

  get favIcon() {
    return this._favIcon;
  }

  _banner: any = {};
  set banner(data: any) {
    this._banner = data;
  }

  get banner() {
    return this._banner;
  }

  afterInit: ReplaySubject<any> = new ReplaySubject(1);
  _afterInitMap: any = { _done: true };

  addCustomPageData(key: string, d: any, id = null) {
    if (!this._pageData[key]) {
      this._pageData[key] = {
        id,
        name: key,
        active: true,
        data: {}
      }
    }

    let data = this._pageData[key].data || {};
    data = { ...data, ...d };
    this._pageData[key].data = data;
    if (id !== null) {
      this._pageData[key].id = id;
    }

    //////console.log("Loaded", key, d);
  }

  hasFAQ = false;
  hasMarketing = false;

  get showExtraPrivacyPolicyCheckbox() {
    return this.companyData?.register_show_extra_privacy_policy_checkbox
  }

  getCustomPageData(key: string) {
    if (!this._pageData[key]) {
      this._pageData[key] = {
        name: key,
        active: true,
        data: {}
      }
    }

    const data = this._pageData[key].data || null;
    if (key === "marketing") {
      this.hasMarketing = !!(data?.html);
    }

    return data || {};
  }

  set formData(fields: any) {
    let data = this.getCustomPageData("register");
    let form = data.form || {};
    form = { ...form, fields };
    data = { ...data, form };
    this.addCustomPageData("register", data);
  }

  get formData() {
    const data = this.getCustomPageData("register");
    return data?.form?.fields || [];
  }

  _themeData: any = {};
  set themeData(data: any) {
    this._themeData.data = data;
  }

  get themeData() {
    return this._themeData.data;
  }

  faqData: any[] = [];
  payoutData: any[] = [];
  companyData: any = {};

  constructor(
    private http: HttpClient,
    private alert: NotificationService,
    private auth: AuthService
  ) { }

  loadPages(publicId = null, pageNames = ["home"]) {
    this._afterInitMap["loadPages"] = false;

    const _onResult = (pages: any[]) => {
      pages.forEach(page => {
        if (pageNames.includes(page.name)) {
          this.addCustomPageData(page.name, page?.data || {}, page?.id || null)
        }
      });

      this._afterInitMap["loadPages"] = true;
      this._checkAfterInit();
    }

    if (publicId === null) {
      this.http.get(`${this._base}/cms/v1/page`).subscribe((data: any[]) => {
        _onResult(data);
      });
      return;
    }

    this.http.get(`${this._base}/cms/v1/page/${publicId}`).subscribe((d: any) => {
      _onResult([d]);
    });
  }

  loadTheme(publicId = null) {
    if (this._afterInitMap["loadTheme"]) {
      this._checkAfterInit();
      return;
    }

    this._afterInitMap["loadTheme"] = false;

    const _onResult = (d: any) => {
      const id = d.id;
      delete d.id
      this.themeData = d;
      this._themeData.id = id;

      this._afterInitMap["loadTheme"] = true;
      this._checkAfterInit();
    }

    if (publicId === null) {
      this.http.get(`${this._base}/cms/v1/theme`).subscribe((data: any[]) => {
        const d = data.filter(d => d.name === "default")[0] || {};
        _onResult(d);
      });
      return;
    }

    this.http.get(`${this._base}/cms/v1/theme/${publicId}`).subscribe((d: any) => {
      _onResult(d);
    });
  }

  _checkAfterInit() {
    let done = true;
    Object.keys(this._afterInitMap).forEach(k => {
      done = done && this._afterInitMap[k];
    });

    if (done) {
      this.afterInit.next();
    }
  }

  loadFAQ(publicId = null) {
    if (this._afterInitMap["loadFAQ"]) {
      this._checkAfterInit();
      return;
    }

    this._afterInitMap["loadFAQ"] = false;

    let request = this.http.get(`${this._base}/cms/v1/faq`);
    if (publicId !== null) {
      request = this.http.get(`${this._base}/cms/v1/affiliate_company/${publicId}/faqs`);
    }

    request.subscribe((faq: any[]) => {
      if (publicId === null) {
        this.http.get(`${this._base}/cms/v1/affiliate_company/faq`).subscribe((d: any) => {
          d.forEach(f => {
            faq = faq.map(fa => {
              if (fa.id === f.def_faq_id) {
                fa.active = f.active;
                fa.affiliate_company_faq_id = f.id;
                fa.order = f.order;
              }

              return fa;
            });
          });

          const unsentFAQs = faq.filter(f => !f.affiliate_company_faq_id).map(f => {
            return { active: false, order: 0, def_faq_id: f.id };
          });

          if (unsentFAQs.length) {
            this.http.post(`${this._base}/cms/v1/affiliate_company/faq`, unsentFAQs).subscribe((d: any[]) => {
              d.forEach(f => {
                faq = faq.map(fa => {
                  if (fa.id === f.def_faq_id) {
                    fa.active = f.active;
                    fa.affiliate_company_faq_id = f.id;
                    fa.order = f.order;
                  }

                  return fa;
                });
              });
            });
          }
        });
      }

      this.hasFAQ = !!faq.length;
      this.faqData = faq;
      this._afterInitMap["loadFAQ"] = true;
      this._checkAfterInit();
    });
  }

  loadPayoutOption() {
    if (this._afterInitMap["loadPayoutOption"]) {
      return;
    }

    this._afterInitMap["loadPayoutOption"] = false;

    this.http.get(`${this._base}/cms/v1/def_payout_option`).subscribe((payout: any[]) => {
      this.payoutData = payout;
      this._afterInitMap["loadPayoutOption"] = true;
      this._checkAfterInit();
    });
  }

  loadCompany(publicId = null) {
    if (this._afterInitMap["loadCompany"]) {
      this._checkAfterInit();
      return;
    }

    this._afterInitMap["loadCompany"] = false;

    let request = this.http.get(`${this._base}/cms/v1/affiliate_company`);
    if (publicId !== null) {
      request = this.http.get(`${this._base}/cms/v1/affiliate_company/${publicId}`);
    }

    request.subscribe((company: any[]) => {
      this.companyData = company;
      this._afterInitMap["loadCompany"] = true;
      //////console.log("AFFFFFF 0 ", this.companyData);
      this._checkAfterInit();
    });
  }

  loadAll() {
    this.auth.onLoginChange.pipe(take(1)).subscribe(_ => {
      this.loadPages(null, ["home", "register", "agb", "impressum", "marketing", "datenschutz"]);
      this.loadTheme();
      this.loadFAQ();
      this.loadPayoutOption();
      this.loadCompany();
    });
  }

  upload(data, type, id = null) {
    let request = this.http.post(`${this._base}/cms/v1/image`, data);
    if (id !== null) {
      request = this.http.patch(`${this._base}/cms/v1/image/` + id, data);
    }

    return request.pipe(map((res: any) => {
      this.http.patch(`${this._base}/cms/v1/affiliate_company`, { [type === "logo" ? "logo_image_id" : type === "fav" ? "favicon_image_id" : "background_image_id"]: res.id || null }).subscribe(_ => {

      });
      return res;
    }));
  }

  theme(data, id = null) {
    delete data.created_at;
    data.color_primary_hover = data.color_secondary;
    let request = this.http.post(`${this._base}/cms/v1/theme`, [data]);
    if (id !== null) {
      delete data.css;
      request = this.http.patch(`${this._base}/cms/v1/theme/` + id, data);
    }

    return request;
  }

  _replaceImages(html: string) {
    return new Promise((resolve, _) => {
      var arr = (/<img.*src[^,]*,([^"]*)">/gm).exec(html);
      arr.forEach((image, i) => {
        if (i === 0) {
          return;
        }

        //////console.log(i, image);
      });
      resolve(html);
    });
  }

  saveAll() {
    this.faqData.forEach((d, i) => {
      this.http.patch(`${this._base}/cms/v1/affiliate_company/faq/${d.affiliate_company_faq_id}`, { active: d.active, order: i }).subscribe(_ => { });
    });

    if (Object.keys(this.themeData).length) {
      this.theme(this.themeData, this._themeData.id || null).subscribe((theme: any) => {
        this.http.patch(`${this._base}/cms/v1/affiliate_company`, { "theme_id": theme.id || null }).subscribe(_ => { });
      });
    }



    this.payoutData.forEach((d, i) => {
      this.http.patch(`${this._base}/cms/v1/def_payout_option/${d.id}`, { active: d.active, order: i }).subscribe(_ => { });
    });

    const { name, email, product_name, website_title, website_description, phone_text, address } = this.companyData;
    this.http.patch(`${this._base}/cms/v1/affiliate_company`, { name, email, product_name, website_title, website_description, phone_text, address }).subscribe(_ => {

    });

    const pagekeys = Object.keys(this._pageData);
    let successcount = 0;
    let done = 0;
    pagekeys.forEach((pageKey) => {
      const { id, name, active, data } = this._pageData[pageKey];
      const sendData: any = { name, active, data };

      let request = this.http.patch(`${this._base}/cms/v1/page/${id}`, sendData);
      if (!id && id !== 0) {
        request = this.http.post(`${this._base}/cms/v1/page`, [sendData]);
      }

      request.subscribe(_ => {
        successcount++;
        done++;

        if (done < pagekeys.length - 1) {
          return;
        }

        this.alertMessage(successcount === pagekeys.length);
      }, _ => {
        done++
        if (done < pagekeys.length - 1) {
          return;
        }

        this.alertMessage(false);
      });
    });
  }

  alertMessage(success: boolean) {
    if (success) {
      this.alert.success("Erfolgreich gespeichert");
      return;
    }

    this.alert.error("Beim speichern trat ein fehler auf!");
  }
}
