export const environment: any = {
  production: true,
  chargepoint_enabled: true,
  referral_enabled: true,
  sentry_dsn: "https://706b2f72c7ee4f5b8d48c6a19b194251@sentry1.sp20.nynex.de/2",
  sentry_debug: false,
  AUTH_URL: "https://api.eauto-cash.de",
  BACK_URL: "https://api.eauto-cash.de",
  ADMIN_URL: "https://admin.eauto-cash.de",
  PROJECT: "eAutoCash",
  //PROJECT: "evCashback",
  //PROJECT: "senec",
  GOOGLE_ANALYTICS_PROPERTY: null, //"UA-42624861-4",
  META_PIXEL_ID: null, //"352526164957191",
  CHATWOOT_RUN: {
    websiteToken: 'XM66teJoNGNdxvrdembhoj1m',
    baseUrl: "https://chatwoot.nynex.de"
  },
  CHATWOOT_ENABLED: false,
  CHATWOOT_SETTINGS: {
    hideMessageBubble: false,
    position: 'left', // This can be left or right
    locale: 'de', // Language to be set
    type: 'expanded_bubble', // [standard, expanded_bubble]
    launcherTitle: 'Chat',
    showPopoutButton: true,
  },
  PROFILE: {
    NICKNAME: true,
    CAN_DONATE_TREES: true,
    CAN_RENEW: true,
    RENEW_NOTE: 'Sie erhalten 90% des Vermarktungs-Erlöses.'
  },
  VARS: {
    URL: "eauto-cash.de",
    NAME: "eAutoCash",
    EMAIL: "info@eauto-cash.de",
    EMAIL_SALES: "sales@eauto-cash.de",
    PHONE: "06151 – 500 74 330",
    PHONE_URL: "tel:+49615150074330",
  }
};
