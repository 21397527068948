import { NgModule } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CoreComponent, StorageService } from '@autocash-core';
import { environment } from '@env';
import { EAutocashModule } from 'e-autocash';
import { EvCashbackModule } from 'ev-cashback';
import { filter, take } from 'rxjs/operators';
import { SenecModule } from 'senec';

var queryRef = null;

const moduleMap = {
  "eAutoCash": EAutocashModule,
  "evCashback": EvCashbackModule,
  "senec": SenecModule,
}

@NgModule({
  imports: [
    moduleMap[environment.PROJECT || "eAutoCash"]
  ],
  declarations: [],
  providers: [],
  bootstrap: [CoreComponent]
})
export class AppModule {
  constructor(
    private router: Router
  ) {

    const _searchToObject = (url) => {
      if (!url) {
        return null;
      }

      var pairs = url.substring(1).split("&"),
        obj = {},
        pair,
        i;

      let res = null;
      for (i in pairs) {
        if (pairs[i] === "") continue;

        pair = pairs[i].split("=");
        obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
        res = obj;
      }

      return res;
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      if(window?.location?.pathname?.includes("home/login")) {
        queryRef = null;
        return;
      }

      const pairs = event.url.replace('/#', '').split("?");
      const url = pairs[0], query = pairs[1];


      if (!queryRef) {
        queryRef = query ? _searchToObject('?' + query) : null;
      }

      let navTarget = [];

      if (!!event.url && event.url?.match(/^\/#/)) {
        navTarget = [url];
      }

      if (queryRef) {
        this.router.navigate(navTarget, {
          queryParams: queryRef,
          queryParamsHandling: 'merge'
        });
      } else if (navTarget.length) {
        this.router.navigate(navTarget);
      }
    });
  }
}
