import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  onUserData: ReplaySubject<any> = new ReplaySubject(1);

  constructor() { }

  pushUserData(data: any) {
    this.onUserData.next(data);
  }
}
