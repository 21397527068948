import { Component, OnDestroy, OnInit } from '@angular/core';
import { StorageService } from '../../../../services/storage.service';
import { Subscription } from 'rxjs';
import { FormService } from '../../../../services/form.service';
import { RegistrationService } from '../../../../services/registration.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit, OnDestroy {
  valid: boolean = true;
  touched: boolean = false;

  _emailValue: string = "";
  set emailValue(ev: string) {
    this.touched = true;
    this._emailValue = ev;
    this.onChange(ev);
  }

  get emailValue() {
    return this._emailValue;
  }

  subs: Subscription[] = [];

  constructor(
    private register: RegistrationService,
    private formService: FormService,
    private storage: StorageService
  ) { }

  ngOnInit(): void {
    let {email} = JSON.parse(this.storage.session.getItem("userdetails") || "{}");
    if(email) {
      this.emailValue = email || "";
    };

    this.subs.push(this.register.onUserData
      .subscribe(params => {
        const email = params?.e || "";
        if(email) {
          this.emailValue = email;
        }
      }));

    this.subs.push(this.formService.onValidate.subscribe(data => {
        this.touched = true;
        if(!data.email) {
          this.validate()
        }
      }));
  }

  onFocus() {
    this.touched = true;
    this.validate();
  }

  validate() {
    function validateEmail(email) {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    this.valid = validateEmail(this.emailValue);
  }

  ngOnDestroy() {
    this.subs.forEach(s => {
      s.unsubscribe();
    })
  }


  onChange(email) {
    this.validate();
    this.formService.answerUserEmail(null);
    
    if(this.valid) {
      this.formService.answerUserEmail(email);
    }
  }
}
