import { CommonModule, NgComponentOutlet } from '@angular/common';
import { Compiler, Component, ComponentRef, Directive, Input, ModuleWithComponentFactories, NgModule, OnChanges, Type, ViewContainerRef } from '@angular/core';
import { CoreModule } from '@autocash-core';

@Directive({
  selector: '[compile]'
})
export class CompileDirective implements OnChanges {
  @Input() compile: string = "";
  @Input() compileContext: any;

  compRef: ComponentRef<any>;

  constructor(private vcRef: ViewContainerRef, private compiler: Compiler) {}

  ngOnChanges() {
    if(!this.compile) {
      if(this.compRef) {
        this.updateProperties();
        return;
      }
      return;
    }

    this.vcRef.clear();
    this.compRef = null;

    const component = this.createDynamicComponent(this.compile);
    const module = this.createDynamicModule(component);
    this.compiler.compileModuleAndAllComponentsAsync(module)
      .then((moduleWithFactories: ModuleWithComponentFactories<any>) => {
        let compFactory = moduleWithFactories.componentFactories.find(x => x.componentType === component);

        this.compRef = this.vcRef.createComponent(compFactory);
        this.updateProperties();
      })
      .catch(error => {
        //////console.log(error);
      });
  }

  updateProperties() {
    for(var prop in this.compileContext) {
      this.compRef.instance[prop] = this.compileContext[prop];
    }
  }

  private createDynamicComponent(template: string) {
    const cmpClass = class CustomDynamicComponent {
    };
    const decoratedCmp = Component({
      selector: 'custom-dynamic-component',
      template: template,
    })(cmpClass);

    return decoratedCmp;
  }

  private createDynamicModule(component: Type<any>) {
    const moduleClass = class CustomDynamicModule {
    };

    const decoratedNgModule = NgModule({
      imports: [CommonModule, CoreModule],
      declarations: [component]
    })(moduleClass);

    return decoratedNgModule;
  }
}