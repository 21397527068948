import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  type = null;
  title = null;
  message = null;

  constructor(
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.notificationService.onMessage.subscribe(m => {
      this.message = m;
    });

    this.notificationService.onType.subscribe(t => {
      this.type = t;
    });

    this.notificationService.onTitle.subscribe(t => {
      this.title = t;
    });
  }

  close() {
    this.message = null;
    this.type = null;
    this.notificationService?.onClose?.();
  }

}
