import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@autocash-core';
import { EAutoCashRoutingModule } from './e-autocash.routing';
import { HomeComponent } from './home/home.component';
import { FleetComponent } from './fleet/fleet.component';
import { ChargingStationComponent } from './charging-station/charging-station.component';
import { B2bComponent } from './b2b/b2b.component';
import { AboutThgComponent } from './about-thg/about-thg.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { TreepageComponent } from './treepage/treepage.component';
import { ClimateComponent } from './treepage/climate/climate.component';

@NgModule({
  declarations: [
    HomeComponent,
    FleetComponent,
    ChargingStationComponent,
    B2bComponent,
    AboutThgComponent,
    AboutUsComponent,
    NavComponent,
    FooterComponent,
    TreepageComponent,
    ClimateComponent
  ],
  imports: [
    EAutoCashRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CoreModule
  ],
  exports: [
    HomeComponent
  ]
})
export class EAutocashModule { }
