import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@autocash-core';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  constructor(private auth: AuthService) {}

  get canLogin() {
    return this.auth.canLogin;
  }

  ngOnInit(): void {
    if(window?.location?.pathname?.includes("home/login")) {
      $('#loginModal').modal('show');
    } else if(window?.location?.pathname?.includes("home/reset")) {
      $('#pwresetModal').modal('show');
    }
  }
}
