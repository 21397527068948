import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from '@env';
import { WizardComponent } from 'angular-archwizard';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ClientService } from '../../services/client.service';
import { CmsReaderService } from '../../services/cms-reader.service';
import { FullscreenService } from '../../services/fullscreen.service';
import { RestService } from '../../services/rest.service';
import { WizardService } from '../../services/wizard.service';

@Component({
  selector: 'app-signup-workflow',
  templateUrl: './signup.component.html'
})
export class SignupComponent implements OnInit, OnDestroy {
  @ViewChild(WizardComponent)
  public wizard: WizardComponent;

  showSymbol = environment?.PROCESS?.SYMBOL !== false

  @Input() inModal = false;

  @Input() forceNewVehicle: boolean = false;
  @Input() skip = 0;

  env = environment;

  uploadPercent = 0;

  @Output() onNext: EventEmitter<any> = new EventEmitter();

  fullscreen: boolean = false;

  @HostListener('window:popstate', ['$event'])
  onPopState(event: Event) {
    history.pushState(null, null, location.href);
    if(this.wizard.currentStepIndex) {
      this.wizardService.prev();
    }
  }

  _stepIndex = 0;
  set stepIndex(si: number) {
    this._stepIndex = si;
    if (si === 0) {
      this.rest.newVehicleOnNextRequest = !!this.forceNewVehicle;
    }
  }

  get stepIndex() {
    return this._stepIndex;
  }

  subs: Subscription[] = [];

  isDone: boolean = false;
  isError: boolean = false;

  hasRevoked: boolean = false;
  
  constructor(
    private cdref: ChangeDetectorRef,
    public wizardService: WizardService,
    private fullscreenService: FullscreenService,
    private rest: RestService,
    private clientService: ClientService,
    private cmsService: CmsReaderService
  ) { }

  isClient(type) {
    return this.clientService.type === type;
  }

  revoke() {
    this.rest.revoke(() => {
      this.hasRevoked = true;
    });
  }

  reset() {
    if (!this.wizard) {
      return;
    }

    setTimeout(() => {
      this.wizard.reset();
      this.stepIndex = 0;
    }, 1);
  }

  ngOnInit(): void {
    this.hasRevoked = false;

    this.subs.push(this.rest.uploadProgress$.subscribe((data: number[]) => {
      const sum = data.reduce((partialSum, a) => partialSum + a, 0);
      this.uploadPercent = sum / data.length;
    }));

    this.cmsService.afterInit.pipe(take(1)).subscribe(_ => {
      if(this.skip === 0 && !this.cmsService.hasFAQ) {
        this.skip = 1;
        this.reset();
      }
    });

    this.wizardService.loaded = true;
    this.stepIndex = 0;

    this.subs.push(this.fullscreenService.onFullscreenChange.subscribe(isFull => {
      this.fullscreen = isFull;
    }));

    this.subs.push(this.wizardService.onStepChange.subscribe(data => {
      this.hasRevoked = false;
      this.uploadPercent = 0;
      
      this.isDone = false;
      this.isError = false;

      if (!this.wizard) {
        return;
      }

      const { step, done, error } = data;
      if (error) {
        this.isError = true;
        this.reset();
        return;
      }

      if (!step && done) {
        this.isDone = true;
        this.reset();
        return;
      }

      if (step > 0) {
        this.onNext.emit(null);
        this.wizard.goToNextStep();
      } else if (step < 0) {
        this.wizard.goToPreviousStep();
      }

      setTimeout(() => {
        this.stepIndex = this.wizard.currentStepIndex;
        this.cdref.detectChanges();
      }, 100);

      setTimeout(() => {
        this.wizardService.loaded = true;
      }, 1000);
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.cdref.detectChanges();
    }, 100);
  }

  next(isDone) {
    //////////console.log("Is Done? ", isDone);
    if (!isDone) {
      return;
    }

    this.wizardService.reset();
  }

  onSubmit() {
    //////////console.log("Done!");
  }
}
