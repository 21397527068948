import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FaqComponent } from '@autocash-core';
import { AgbComponent } from './agb/agb.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { HomeComponent } from './home/home.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'login',
        component: HomeComponent,
      },
      {
        path: 'reset',
        component: HomeComponent,
      }
    ]
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'agb',
    component: AgbComponent,
    data: {
      title: 'AGB Page',
      show: true,
    }
  },
  {
    path: 'impressum',
    component: ImpressumComponent,
    data: {
      title: 'Impressum Page',
      show: true,
    }
  },
  {
    path: 'datenschutz',
    component: DatenschutzComponent,
    data: {
      title: 'Datenschutz Page',
      show: true,
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class SenecRoutingModule { }
