import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FullscreenService {
  onFullscreenChange: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor() { }

  fullscreen(isFull: boolean) {
    this.onFullscreenChange.next(isFull);
  }

  public openFullscreen(elem) {
    return;

    // TOO BUGGY ON IPHONE
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  public closeFullscreen() {
    return;

    // TOO BUGGY ON IPHONE
    if (document.fullscreenElement === null) {
      return;
    }

    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((<any> document).webkitExitFullscreen) { /* Safari */
     (<any> document).webkitExitFullscreen();
    } else if ((<any> document).msExitFullscreen) { /* IE11 */
      (<any> document).msExitFullscreen();
    }
  }
}
