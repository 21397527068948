import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'app-widerruf',
  templateUrl: './widerruf.component.html',
  styleUrls: ['./widerruf.component.scss']
})
export class WiderrufComponent implements OnInit {

  env = environment;

  @Input() embedded = false;
  @Input() title = "§1 Widerrufsrecht";

  constructor() { }

  ngOnInit(): void {
  }

}
