import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgxDropzoneComponent } from 'ngx-dropzone';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnDestroy, AfterViewInit {    
  @Input() single: boolean = false;
  @Input() resetOnImage: boolean = false;
  @Input() dropText: string = ""

  @ViewChild('hack') hack: ElementRef;
  @ViewChild("dropZone", {static: false}) dropZone: ElementRef<NgxDropzoneComponent>;

  @Input() nextText: string = 'Weiter';

  bg;
  _bgImage: string = "";
  @Input() set bgImage(bg: string) {
    this.bg = bg;
    this._bgImage = bg;
  }

  get bgImage() {
    return this._bgImage;
  }

  @Output() onData: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  files: File[] = [];  
  fileData: any[] = []

  containerHeight: string = 'auto';

  constructor(
    private cdref: ChangeDetectorRef,
  ) { }  
  
  ngOnInit() { }

  ngAfterViewInit(): void {
    this.onResize();
  }

  ngOnDestroy(): void {
    this.reset();
  }

  public click() {
    //@ts-ignore
    this.dropZone.showFileSelector();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const hack = this.hack.nativeElement;
    this.containerHeight = this.files.length === 0 ? (hack.clientWidth*0.502) + 'px' : 'auto';
    this.cdref.detectChanges();
  }

  onSelect(event) {
    if(this.single) {
      this.files = [];
    }

    this.files.push(...event.addedFiles);  

    this.fileData = [];
    this.files.forEach((file, i) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const res = reader.result;
        this.fileData.push({name: file.name, data: res});
        
        if(i === this.files.length-1) {
          this.onData.emit(this.fileData);
        }

        if(this.resetOnImage) {
          this.files = [];
        }
      };
    });

  }  
  
  onRemove(event) {
      this.files.splice(this.files.indexOf(event), 1);  
      this.fileData.splice(this.files.indexOf(event), 1);
      this.onData.emit(this.fileData);
      this.onDelete.emit();
  }  

  public reset() {
    this.files = [];
    this.fileData = [];
  }
}
