import { environment as devEnv } from "./environment.prod";
//import { environment as devEnv } from "./environment.ev-cashback";

export const environment = {
  ...devEnv,
  production: false,
  sentry_dsn: null, //"https://5db7750f4d594393811ba69657617f7d@sentry1.sp20.nynex.de/4",
  sentry_debug: false,
  AUTH_URL: "https://api.test.eauto-cash.de",
  BACK_URL: "https://api.test.eauto-cash.de",
  ADMIN_URL: "https://admin.test.eauto-cash.de",
  GOOGLE_ANALYTICS_PROPERTY: "UA-42624861-4",
  META_PIXEL_ID: "352526164957191",
  CHATWOOT_RUN: {
    ...devEnv.CHATWOOT_RUN,
    websiteToken: 'WnTWPpessja72tkmJ7GhweXp',
  },
  VARS: {
    ...devEnv.VARS,
    URL: "test.eauto-cash.de",
  }
};
