import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { environment } from '@env';
import { Subscription } from 'rxjs';
import { CmsReaderService } from '../../../services/cms-reader.service';
import { FormService } from '../../../services/form.service';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-agb',
  templateUrl: './agb.component.html',
  styleUrls: ['./agb.component.scss']
})
export class AgbComponent implements OnInit, OnDestroy {

  smallMarketing = !!environment?.PROCESS?.MARKETING?.SMALL;
  hasMarketing = true;
  showExtraPrivacyPolicyCheckbox = false;

  subs: Subscription[] = [];

  agbValid = null;
  privacyPolicyValid = null;

  _agb: boolean = false;
  set agb(agb: boolean) {
    this._agb = agb;
    this.agbValid = !!agb;
    this.formService.answerAGB(agb);
    // set privacyPolicy together with AGB if showExtraPrivacyPolicyCheckbox is disabled
    if (!this.showExtraPrivacyPolicyCheckbox) {
      this.privacyPolicy = agb
    }
  }

  get agb() {
    return this._agb;
  }

  _privacyPolicy: boolean = undefined;
  set privacyPolicy(privacyPolicy: boolean) {
    this._privacyPolicy = privacyPolicy;
    this.privacyPolicyValid = !!privacyPolicy;
    this.formService.answerPrivacyPolicy(privacyPolicy);
  }

  get privacyPolicy() {
    return this._privacyPolicy;
  }

  _marketing: boolean = false;
  set marketing(marketing: boolean) {
    this._marketing = marketing;
    this.formService.answerMarketing(marketing);
  }

  get marketing() {
    return this._marketing;
  }


  constructor(
    private formService: FormService,
    private cmsService: CmsReaderService,
    private storage: StorageService
  ) { }

  ngOnInit(): void {
    let { agb, privacyPolicy } = JSON.parse(this.storage.session.getItem("userdetails") || "{}");
    if (agb) {
      this.agb = agb || false;
    };

    if (privacyPolicy) {
      this.privacyPolicy = privacyPolicy || false;
    };

    this.subs.push(this.cmsService.afterInit.subscribe(_ => {
      this.hasMarketing = this.cmsService.hasMarketing;
      this.showExtraPrivacyPolicyCheckbox = this.cmsService.showExtraPrivacyPolicyCheckbox;
    }));

    this.formService.answerAGB(!!this.agb);
    this.formService.answerMarketing(!!this.marketing);
    this.formService.answerPrivacyPolicy(!!this.privacyPolicy);

    this.subs.push(this.formService.onValidate.subscribe(data => {
      this.agbValid = !!data.agb;
      this.privacyPolicyValid = !!data.privacyPolicy;
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
