import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { uuid4 } from '@sentry/utils';
import { RestService } from '../../services/rest.service';

@Component({
  selector: 'app-revoke',
  templateUrl: './revoke.component.html',
  styleUrls: ['./revoke.component.scss']
})
export class RevokeComponent implements OnInit {
  id: string = uuid4();

  @Input() disabled: boolean = false;
  @Output() onRevoke: EventEmitter<boolean> = new EventEmitter();

  _revoke: boolean = false;
  @Input() set revoke(r: boolean) {
    this._revoke = r;
    this.onRevoke.emit(r);
  }

  get revoke() {
    return this._revoke;
  }

  @Input() vehicle = null;

  constructor(
    private rest: RestService
  ) { }

  ngOnInit(): void {
    this._revoke = this.vehicle.revoke;
    if (this._revoke) {
      this.disabled = true;
    }
  }

  public sendRevoke(cb = null) {
    if (!(this._revoke && this.vehicle)) {
      return;
    }

    this.rest.sendRevoke(this.vehicle.vehicle_id, this.vehicle.annual_user_payout_id).subscribe(data => {
      this.disabled = true;
      if (cb) {
        cb();
      }
    });
  }
}
