import { Component, OnInit } from '@angular/core';
import { AffiliateService, ClientService } from '@autocash-core';

declare var $: any;

@Component({
  selector: 'app-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.scss']
})
export class FleetComponent implements OnInit {

  constructor(
    private clientService: ClientService,
    private affiliateService: AffiliateService
  ) { }

  get payoutFixed() {
    return this.affiliateService.payoutFixed;
  }

  get payoutPercentage() {
    return this.affiliateService.payoutPercentage;
  }

  ngOnInit(): void {
    this.clientService.setType("fleet");
  }

  scrollTo(id: string) {
    $(id)[0]?.scrollIntoView();
  }
}
