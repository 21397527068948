import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountComponent } from './account/account.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignupComponent } from './signup/signup.component';
import { DetailsComponent } from './signup/details/details.component';
import { PhotoComponent } from './signup/photo/photo.component';
import { AgbComponent } from './signup/agb/agb.component';
import { FaqComponent } from './signup/faq/faq.component';
import { BenefitComponent } from './signup/benefit/benefit.component';
import { EmailComponent } from './signup/details/email/email.component';
import { ImpressumComponent as ImpressumLegalComponent } from './legal/impressum/impressum.component';
import { AgbComponent as AgbLegalComponent } from './legal/agb/agb.component';
import { DatenschutzComponent as DatenschutzLegalComponent } from './legal/datenschutz/datenschutz.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ArchwizardModule } from 'angular-archwizard';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MultiClientComponent } from './signup/multi-client/multi-client.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { AffiliateComponent } from './legal/affiliate/affiliate.component';
import { RevokeComponent } from './revoke/revoke.component';
import { ModalsComponent } from './modals/modals.component';
import { AnalyticsDirective } from './directives/analytics.directive';
import { CustomContainerComponent } from './custom-container/custom-container.component';
import { CustomComponentDirective } from './directives/custom-component.directive';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { FormBuilderComponent } from './form-builder/form-builder.component';
import { EditorComponent } from './editor/editor.component';
import { FaqSelectorComponent } from './faq-selector/faq-selector.component';
import { PaymentOptionSelectorComponent } from './payment-option-selector/payment-option-selector.component';
import { CompanyDataComponent } from './company-data/company-data.component';
import { LoginComponent } from './login/login.component';
import { PasswordResetComponent } from './login/password-reset/password-reset.component';
import { ExtendVehicleComponent } from './extend-vehicle/extend-vehicle.component';
import { WiderrufComponent } from './legal/widerruf/widerruf.component';

const COMPONENTS = [
  SignupComponent,
  DetailsComponent,
  PhotoComponent,
  AgbComponent,
  FaqComponent,
  BenefitComponent,
  EmailComponent,
  AccountComponent,
  FileUploadComponent,
  ImpressumLegalComponent,
  AgbLegalComponent,
  DatenschutzLegalComponent,
  MultiClientComponent,
  ContactFormComponent,
  AffiliateComponent,
  RevokeComponent,
  ModalsComponent,
  CustomContainerComponent,
  ColorPickerComponent,
  FormBuilderComponent,
  EditorComponent,
  FaqSelectorComponent,
  PaymentOptionSelectorComponent,
  CompanyDataComponent,
  LoginComponent,
  PasswordResetComponent,
  ExtendVehicleComponent,
  //Directives 
  AnalyticsDirective,
  CustomComponentDirective,
  WiderrufComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    WiderrufComponent,
  ],
  exports: [
    ...COMPONENTS
  ],
  imports: [
    NgxDropzoneModule,
    ArchwizardModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class SharedModule { }
