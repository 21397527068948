import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss']
})
export class MarketingComponent implements OnInit {
  active: boolean = false;

  constructor() { }

  ngOnInit(): void {
    $("#customRadioInline2").on("click", () => {
      this.active = !this.active;
    });
  }

  clickLabel() {
    $("#customRadioInline2").click();
  }

}
