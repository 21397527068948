import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

import jwt_decode from 'jwt-decode';
import { environment } from '@env';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import * as Sentry from "@sentry/angular";

import * as moment from 'moment';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { NotificationService } from './notification.service';

const AUTH_KEY = "auth";
const REFRESH_KEY = "refresh";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  onSaveProfile$: Subject<any> = new Subject();

  get canLogin() {
    return true;
  }

  _base = environment.AUTH_URL;

  referralCode: string = null;
  affiliateCode: string = null;

  _user: any = {};

  _canRef: boolean = null;
  set canRef(r: boolean) {
    if (this._canRef === r) {
      return;
    }

    this._canRef = r;
    this.onPermissionChange.next();
  }

  get canRef() {
    return this._canRef;
  }

  _canAffiliate: boolean = null;
  set canAffiliate(a: boolean) {
    if (this._canAffiliate === a) {
      return;
    }

    this._canAffiliate = a;
    this.onPermissionChange.next();
  }

  get canAffiliate() {
    return this._canAffiliate;
  }

  _canChargingstation: boolean = false;
  set canChargingstation(a: boolean) {
    if (this._canChargingstation === a) {
      return;
    }

    this._canChargingstation = a;
    this.onPermissionChange.next();
  }

  get canChargingstation() {
    return this._canChargingstation;
  }

  _canB2b: boolean = null;
  set canB2b(b: boolean) {
    if (this._canB2b === b) {
      return;
    }

    this._canB2b = b;
    this.onPermissionChange.next();
  }

  get canB2b() {
    return this._canB2b;
  }

  _canPayout: boolean = null;
  set canPayout(b: boolean) {
    if (this._canPayout === b) {
      return;
    }

    this._canPayout = b;
    this.onPermissionChange.next();
  }

  get canPayout() {
    return this._canPayout;
  }

  isPasswordLogin = false;

  onPermissionChange: ReplaySubject<any> = new ReplaySubject(1);

  __refresh = null;
  set _refresh(t: any) {
    if (!t) {
      this.__refresh = null;
      this.storageService.session.removeItem(REFRESH_KEY);
      return;
    }

    this.__refresh = t;
    this.storageService.session.setItem(REFRESH_KEY, t);
  }

  get _refresh() {
    return this.__refresh;
  }

  __jwt = null;
  set _jwt(t: any) {
    if (!t) {
      this.__jwt = null;
      this.storageService.session.removeItem(AUTH_KEY);
      return;
    }

    this.__jwt = t;
    this.storageService.session.setItem(AUTH_KEY, t);
  }

  get _jwt() {
    return this.__jwt;
  }

  _payload = null;

  onUserChange: ReplaySubject<any> = new ReplaySubject(1);
  onLoginChange: ReplaySubject<any> = new ReplaySubject(1);
  onSignupChange: ReplaySubject<any> = new ReplaySubject(1);

  get token() {
    return this._jwt;
  }

  get rfToken() {
    return this._refresh;
  }

  requestEmail(email) {
    Sentry.setUser({ email: email });
    this._updateUser({ email: email });
    return this.http.post(`${this._base}/v2/user/login_request`, { email });
  }

  isAuthenticated() {
    return !!this._jwt && !!this._payload;
  }

  hasTemptoken() {
    return !!this._payload && this._payload.aud.includes("/signup");
  }

  get expiresInSeconds() {
    if (!this._payload) {
      return 0;
    }

    const exp = this._payload.exp - moment.utc().valueOf() / 1000;
    return exp;
  }

  _updateUser(data: any) {
    this._user = Object.assign({}, this._user, data);
    //////console.log("this._updateUser", this._user);
    this.onUserChange.next(this._user);
  }

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private note: NotificationService
  ) {
    this._jwt = storageService.session.getItem(AUTH_KEY) || null;
    this._refresh = storageService.session.getItem(REFRESH_KEY) || null;
    this._payload = this.getPayload();

    this.isPasswordLogin = this._payload?.auth_type === "password";

    setTimeout(() => {
      if (!this._jwt) {
        return;
      }

      this.onLoginChange.next(this._jwt);
    }, 200);
  }

  getPayload() {
    if (!this._jwt) {
      return null;
    }

    try {
      this._payload = jwt_decode(this._jwt);
    } catch (Error) {
      this._invalidateToken(true);
    }

    return this._payload;
  }

  get canRedirect() {
    return !(window?.location?.pathname?.includes("home") || window?.location?.pathname?.includes("password_"))
  }

  _lastVehicleId = null;

  get lastVehicleId() {
    return this._lastVehicleId;
  }

  _handleToken(data: any) {
    const { auth_token, refresh_token, vehicle_id, chatwoot_identifier, chatwoot_identifier_hash, is_admin_login } = data;
    this._jwt = auth_token || null;
    this._refresh = refresh_token || null;
    this._payload = this.getPayload();

    this._lastVehicleId = vehicle_id;

    this._updateUser({ id: this._payload?.sub || null, chatwoot_identifier, chatwoot_identifier_hash, is_admin_login });
  }

  _invalidateToken(showAlert) {
    this._payload = null;
    this._jwt = null;
    this._refresh = null;

    if (this.canRedirect) {
      this.router.navigate(['home/login']);
    }
  }

  signup(user) {
    user.email = user.email?.trim();
    Sentry.setUser({ email: user.email || null });
    this._updateUser({ email: user.email || null });

    if (this.referralCode) {
      user.referral_code = this.referralCode;
    }

    if (this.affiliateCode) {
      user.affiliate_code = this.affiliateCode;
    }
    user.referrer = document.referrer || null;
    user.signup_href = window.location.href || null;

    return this.http.post(`${this._base}/v1/signup`,
      user
    ).pipe(
      tap(data => {
        this._handleToken(data);
      })
    );
  }

  changePW(data) {
    return this.http.patch(`${this._base}/v1/user/pw`, data);
  }

  forgotPW(email) {
    email = email?.trim();
    this._updateUser({ email: email });

    return this.http.post(`${this._base}/v1/user/pw`, {
      email
    });
  }

  refresh() {
    return this.http.get(`${this._base}/v1/refresh`).subscribe(data => {
      this._handleToken(data);
      this.onLoginChange.next(this._jwt);
    }, err => {
      this.logout();
    });
  }

  loginByCodeV1(email, code) {
    email = email?.trim();

    Sentry.setUser({ email: email });
    this._updateUser({ email: email });
    return this.http.post(`${this._base}/v1/user/login`,
      {
        email,
        code
      }).pipe(
        tap(data => {
          this._handleToken(data);
          this.onLoginChange.next(this._jwt);
        })
      )
  }

  loginByCodeV2(id, code, master_login = false) {
    Sentry.setUser({ id: id });
    this._updateUser({ id: id, is_admin_login: master_login });
    return this.http.post(`${this._base}/v2/user/login`,
      {
        id,
        code,
        master_login: master_login === true ? 1 : 0,
      }).pipe(
        tap(data => {
          this._handleToken(data);
          this.onLoginChange.next(this._jwt);
        })
      )
  }

  get secureLogin() {
    return {
      init: () => {
        return new Promise((resolve, reject) => {
          this.http.get(`${this._base}/v1/user/password`)
            .subscribe(data => {
              resolve(data);
            }, err => {
              this.logout();
              reject();
            });
        });
      },
      revover: (email) => {
        email = email?.trim();
        this._updateUser({ email: email });
        return new Promise((resolve, reject) => {
          this.http.post(`${this._base}/v1/user/password/recovery/request`, { email })
            .subscribe(data => {
              resolve(data);
            }, err => {
              this.logout();
              reject();
            });
        });
      },
      setPw: (pw, password_code, id) => {
        pw = pw?.trim();
        return new Promise((resolve, reject) => {
          this.http.post(`${this._base}/v1/user/password/recovery`, { password: pw, password_code, id })
            .subscribe(data => {
              resolve(data);
            }, err => {
              this.logout();
              reject();
            });
        })
      },
      login: (email, password, type = "password") => {
        email = email?.trim();
        password = password?.trim();
        this._updateUser({ email: email });

        return new Promise((resolve, reject) => {
          this.http.post(`${this._base}/v1/user/login`,
            {
              email,
              [type]: password
            }
          ).subscribe(data => {
            this._handleToken(data);
            setTimeout(() => {
              this.onLoginChange.next(this._jwt);
              resolve(data);
            }, 100);
          }, err => {
            this.logout();
            reject();
          });
        });
      }
    }
  }

  logout(showAlert = true) {
    this._invalidateToken(showAlert);
    this.onLoginChange.next(null);
  }
}
