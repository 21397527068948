import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { CmsService } from '../../services/cms.service';

@Component({
  selector: 'app-company-data',
  templateUrl: './company-data.component.html',
  styleUrls: ['./company-data.component.scss']
})
export class CompanyDataComponent implements OnInit {

  data: any = {
    address: {}
  };

  constructor(
    private cms: CmsService
  ) { }

  ngOnInit(): void {
    this.cms.afterInit.subscribe(_ => {
      this.data = this.cms.companyData;
      if(!this.data.address) {
        this.data.address = {};
      }
    });
  }

  onFocus() {

  }

  onChange() {
    this.cms.companyData = this.data;
  }

}
