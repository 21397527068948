import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import Pickr from '@simonwep/pickr';
import { ExternalScriptService } from '../../services/external-script.service';

declare var $: any;

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit, AfterViewInit {
  id: string = ""

  picker: any = null;

  _initialColor: string
  @Input() set initialColor(ic: string) {
    this._initialColor = ic;
    if(this.picker) {
      this.picker.setColor(ic);
    }
  }

  get initialColor() {
    return this._initialColor;
  }

  @Output() color: EventEmitter<string> = new EventEmitter();

  constructor(
    private external: ExternalScriptService,
  ) { }

  ngOnInit(): void {
    this.id = "c" + uuidv4();
    //////console.log(this.id);
  }

  ngAfterViewInit(): void {
    this.external.loadExternalCss("https://cdn.jsdelivr.net/npm/@simonwep/pickr/dist/themes/classic.min.css").then(_ => {
      this.picker = new Pickr({
        el: '#' + this.id,
        default: this.initialColor?.replace("#", "") || '303030',
        components: {
          preview: true,
          opacity: true,
          hue: true,
  
          interaction: {
            hex: false,
            rgba: false,
            hsla: false,
            hsva: false,
            cmyk: false,
            input: true,
            clear: false,
            save: true
          }
        }
      });
  
      $('.pcr-save').val("Speichern").on("click", () => {
        if (!this.picker.isOpen()) {
          return;
        }
  
        this.color.emit(this.picker.getColor().toHEXA().toString());
        this.picker.hide();
      });
    });
  }

}
