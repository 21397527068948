import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import * as Sentry from "@sentry/angular";

import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private auth: AuthService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const isRefresh = req.url.includes("/v1/refresh");
    //////////console.log("Isrefresh = ", isRefresh);
    if (this.auth.token || this.auth.rfToken) {
      //////////console.log("Hier ein? = ");
      req = req.clone({
        headers: req.headers
          .set('Authorization', `Bearer ${isRefresh ? this.auth.rfToken : this.auth.token}`)
      });
    }

    //////////console.log("Thes goooo", req);

    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          //////////console.log(event.status);
        }

      }, error => {
        if (error.status === 401) {
          this.auth.logout();
        }
        if (error instanceof HttpErrorResponse) {
          let msg = this.getMessage(req, error);
          Sentry.captureException(new Error(msg), {
            contexts: {
              HttpErrorResponse: {
                request: req,
                response: error,
              }
            }
          });
        }
      }))
  }

  private getMessage(request: HttpRequest<any>, response: HttpErrorResponse): string {
    const messageTemplate = 'HTTP request failed ({method}, {status}, {url})'
    const replace = (msg: string) => {
      const map = {
        method: request.method,
        url: request.url,
        status: response.status,
        message: response.message,
      }

      // replace all keys with their values
      Object.keys(map).forEach(key => {
        msg = msg.replace(new RegExp(`{${key}}`, 'g'), map[key])
      })

      return msg
    }

    return replace(messageTemplate)
  }
}