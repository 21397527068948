import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  get inIframe() {
    return window !== window.parent;
  }

  get session() {
    return {
      setItem: (key: string, value: any) => {
        if(this.inIframe) {
          return;
        }

        sessionStorage.setItem(key, value);
      },
      getItem: (key: string) => {
        if(this.inIframe) {
          return null;
        }

        return sessionStorage.getItem(key);
      },
      removeItem: (key: string) => {
        if(this.inIframe) {
          return;
        }

        sessionStorage.removeItem(key);
      }
    }
  }

  get local() {
    return {
      setItem: (key: string, value: any) => {
        if(this.inIframe) {
          return;
        }
        ////console.log("set", key, value);
        localStorage.setItem(key, value);
      },
      getItem: (key: string) => {
        if(this.inIframe) {
          ////console.log("Im in an iFrame")
          return null;
        }
        ////console.log("get", key);
        return localStorage.getItem(key);
      },
      removeItem: (key: string) => {
        if(this.inIframe) {
          return;
        }
        localStorage.removeItem(key);
      }
    }
  }
}
