import { Component, OnInit } from '@angular/core';
import { FormService } from '@autocash-core';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {
  _customData = {};

  _meinSenecId: string = "";
  set meinSenecId(d: string) {
    this._meinSenecId = d;
    this._customData = { ...this._customData, "meinSenecId": d };
    this.formService.customUserInfo(this._customData);
  }

  get meinSenecId() {
    return this._meinSenecId;
  }

  constructor(
    private formService: FormService
  ) { }

  ngOnInit(): void {
  }

}
