import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { v4 as uuidv4 } from 'uuid';
import { ExternalScriptService } from '../../services/external-script.service';

declare var $: any;

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, AfterViewInit {
  @Input() clean: boolean = false;
  @Output() change: EventEmitter<any> = new EventEmitter();

  @ViewChild("editor", { static: true }) editorRef: ElementRef;

  id: string = "";
  modalId: string = "";

  editor: any = null;

  _html: any;
  _htmlText: string = "";
  @Input() set html(html: string) {
    //////console.log("HTML = ", html);
    this._htmlText = html;
    this._html = this.domSanitizer.bypassSecurityTrustHtml(html);
    this.change.emit(html);
  }

  get html() {
    return this._html;
  }

  constructor(
    private external: ExternalScriptService,
    private domSanitizer: DomSanitizer,
    private cdref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.id = "s" + uuidv4();
    this.modalId = "m" + uuidv4();
  }

  _checkDefault(e) {
    let node, done = false;
    while (!done) {
      if (!node) {
        node = window.getSelection().anchorNode;
      }

      node = node.parentNode;
      const n = node.nodeName;
      const cn = node.className;
      switch (n.toLowerCase()) {
        case 'div':
          if (cn.includes("acash-disabled")) {
            done = true;
            e.preventDefault();
            e.stopPropagation();
          }

          break;
        case 'body':
        default:
          done = true;
          break;
      }
    }
  }

  _checkBackspace(e) {
    let node, done = false;
    while (!done) {
      if (!node) {
        node = window.getSelection().anchorNode;
      }

      node = node.parentNode;
      const n = node.nodeName;
      const cn = node.className;
      switch (n.toLowerCase()) {
        case 'div':
          if (cn.includes("note-editable")) {
            done = true;
          }

          if (cn.includes("acash-root")) {
            done = true;
            if (confirm("Wollen Sie wirklich das gesamte Grid löschen?")) {
              const range = document.createRange();
              range.selectNode(node);
              range.deleteContents();
            } else {
              e.preventDefault();
              e.stopPropagation();
            }
          }

          if (cn.includes("acash")) {
            if (node.children.length <= 1) {
              e.preventDefault();
              e.stopPropagation();
            } else {
              done = true;
            }
          }

          break;
        case 'h2':
        case 'p':
        default:
          done = true;
          break;
      }
    }
  }

  ngAfterViewInit(): void {
    this.external.loadExternalScript("https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.0/js/bootstrap.bundle.min.js").then(_ => {
      this.external.loadExternalScript("https://cdnjs.cloudflare.com/ajax/libs/summernote/0.8.20/summernote-bs4.min.js").then(_ => {
        this.external.loadExternalCss("https://cdnjs.cloudflare.com/ajax/libs/summernote/0.8.20/summernote-bs4.min.css").then(_ => {

          setTimeout(() => {
            this.editor = $(this.editorRef.nativeElement);
            setTimeout(() => {
              this.editor.summernote({
                airMode: this.clean,
                popover: {
                  image: [
                    ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter']],
                    ['remove', ['removeMedia']]
                  ],
                  air: [
                    ['style', ['bold']],
                    ['para', ['ul', 'paragraph']],
                    ['insert', ['link', 'picture']],
                  ]
                },
                toolbar: [
                  // [groupName, [list of button]]
                  ['style', ['bold']],
                  ['para', ['ul', 'paragraph']],
                  ['insert', ['link', 'picture']],
                ],
                callbacks: {
                  onChange: () => {
                    this.onChange(this.editor.summernote("code"))
                  },
                  onBlur: () => {
                    this.onChange(this.editor.summernote("code"))
                  },
                  onInit: () => {
                    this._makeCommonButtons();
                    this._makeGridButton();
                    this._makeTestButton();
                    this.editor.summernote('code', this._htmlText);
                    setTimeout(() => {
                      this.cdref.detectChanges();
                    }, 100)
                  },
                  onImageUpload: (image) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(image[0]);
                    reader.onload = () => {
                      const res = reader.result;
                      const img = $('<img style="width: 100%" />').attr({ src: res })
                      this.editor.summernote('insertNode', img[0]);
                    }
                  },
                  onKeydown: (e) => {
                    if (e.keyCode === 8) {
                      this._checkBackspace(e);
                      return;
                    }

                    this._checkDefault(e);
                  }
                },
              });
            }, 500);
          }, 500);
        });
      });
    });
  }

  _isInEditor(selection) {
    let node, done = false;
    while (true) {
      if (!node) {
        node = selection.anchorNode;
      }

      node = node.parentNode;
      const n = node.nodeName;
      const cn = node.className;
      if (cn.includes("note-editable")) {
        return true;
      }

      if (n === "BODY") {
        return false;
      }
    }
  }

  _convertTo(tag) {
    const selection = window.getSelection();
    if (!this._isInEditor(selection)) {
      return;
    }

    let text = selection.anchorNode.textContent;
    text = text.replace(/[<].*[>]/i, '')
    const range = document.createRange();

    let node: any = selection.anchorNode.parentNode;
    if (node?.className.includes("note-editable")) {
      node = selection.anchorNode;
    }

    range.selectNode(node);
    range.deleteContents();

    const costomTag = document.createElement(tag);
    costomTag.innerHTML = text;
    range.insertNode(costomTag);
  }

  _makeCommonButtons() {
    const titleId = "my" + uuidv4(), normalId = "my" + uuidv4(), regFormId = "my" + uuidv4(), contactFormId = "my" + uuidv4();
    const titleBtn = '<button id="' + titleId + '" type="button" class="note-btn btn btn-light btn-sm" title="Zur Überschrift machen"><i class="bx bx-heading"></i></button>';
    const normalBtn = '<button id="' + normalId + '" type="button" class="note-btn btn btn-light btn-sm" title="Zum Text machen"><i class="bx bx-text"></i></button>';
    const regFormBtn = '<button id="' + regFormId + '" type="button" class="note-btn btn btn-light btn-sm" title="Registrierungsformular"><i class="bx bxs-registered"></i></button>';
    const contactFormBtn = '<button id="' + contactFormId + '" type="button" class="note-btn btn btn-light btn-sm" title="Kontaktformular"><i class="bx bxs-contact"></i></button>';
    var fileGroup = '<div class="note-btn-group btn-group note-insert">' + titleBtn + normalBtn + regFormBtn + contactFormBtn + '</div>';

    $(fileGroup).appendTo($('#' + this.id + ' .note-toolbar'));

    $('#' + titleId).tooltip({ container: 'body', placement: 'bottom' });
    $('#' + titleId).click((event) => {
      this._convertTo("h2");
    });

    $('#' + normalId).tooltip({ container: 'body', placement: 'bottom' });
    $('#' + normalId).click((event) => {
      this._convertTo("p");
    });

    $('#' + regFormId).tooltip({ container: 'body', placement: 'bottom' });
    $('#' + regFormId).click((event) => {
      const code = $('#' + this.id + ' .note-editable').html();
      $('#' + this.id + ' .note-editable').html(code + '<p><br></p><div id="appRegister" class="acash-root acash-disabled" style="width:100%;padding:20px;text-align:center;font-weight:600;color:white;background:#000;">REGISTRIERUNGS FORMULAR</div><p><br></p>');
    });

    $('#' + contactFormId).tooltip({ container: 'body', placement: 'bottom' });
    $('#' + contactFormId).click((event) => {
      const code = $('#' + this.id + ' .note-editable').html();
      $('#' + this.id + ' .note-editable').html(code + '<p><br></p><div id="appContact" class="acash-root acash-disabled" style="width:100%;padding:20px;text-align:center;font-weight:600;color:white;background:#000;">KONTAKT FORMULAR</div><p><br></p>');
    });
  }

  _makeGridButton() {
    const id = "my" + uuidv4();
    var noteBtn = '<button id="' + id + '" type="button" class="note-btn btn btn-light btn-sm" title="Grid erstellen"><i class="bx bxs-grid"></i></button>';
    var fileGroup = '<div class="note-btn-group btn-group note-insert">' + noteBtn + '</div>';
    $(fileGroup).appendTo($('#' + this.id + ' .note-toolbar'));
    // Button tooltips
    $('#' + id).tooltip({ container: 'body', placement: 'bottom' });
    // Button events
    $('#' + id).click((event) => {
      const code = $('#' + this.id + ' .note-editable').html();
      $('#' + this.id + ' .note-editable').html(code + '<div class="row acash-root"><div class="col-12 col-md-6 acash-child"><p><br></p></div><div class="col-12 col-md-6 acash-child"><p><br></p></div></div><p><br></p>');
    });
  }

  _makeTestButton() {
    const id = "my" + uuidv4();
    const linkId = "my" + uuidv4();
    var noteBtn = '<button id="' + id + '" type="button" class="note-btn btn btn-light btn-sm" title="Ansicht testen"><i class="bx bx-window-open"></i></button>';
    var fileGroup = '<div class="note-btn-group btn-group note-insert">' + noteBtn + '</div><a id="' + linkId + '" style="display: none" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#' + this.modalId + '"></a>';
    $(fileGroup).appendTo($('#' + this.id + ' .note-toolbar'));
    // Button tooltips
    $('#' + id).tooltip({ container: 'body', placement: 'bottom' });
    // Button events
    $('#' + id).click((event) => {
      setTimeout(() => {
        $('#' + linkId)[0].click();
      }, 50);
    });
  }

  onChange(code: any) {
    this.html = code;
  }
}
