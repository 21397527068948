import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService, ThemeService } from '@autocash-core';
import { environment } from '@env';

@Component({
  selector: 'app-custom-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  isScrolled: boolean = false;

  environment = environment;

  constructor(
    private themeService: ThemeService,
    private authService: AuthService
  ) { }

  get canLogin() {
    return this.authService.canLogin;
  }

  ngOnInit(): void {
  }

  getBg() {
    return 'url("'+this.themeService.images.background.src+'") no-repeat center / cover fixed';
  }

  getLogoSrc() {
    return this.themeService.images.logo.src;
  }

  @HostListener('document:scroll', ['$event'])
  onScroll(event) {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    this.isScrolled = top >= 40;
  }
}
