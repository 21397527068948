
import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env';
import { CmsReaderService } from '../../../services/cms-reader.service';
import { WizardService } from '../../../services/wizard.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  @Input() standalone: boolean = true;

  env = environment;

  faqs: any[] = [
    {
      title: "Profitieren Sie von Ihren eingesparten Emissionen und holen Sie das meiste aus Ihrem e-Auto heraus.",
      texts: [
        "Auf EU-Ebene wurde mit der Richtlinie RED 2 (Renewable Energy Directive) alles in die Wege geleitet, um den Anteil der erneuerbaren Energien in den Sektoren Transport, Strom und Wärme bis zum Jahr 2030 zu erhöhen. Mit der Richtlinie wurde ein EU-weites verbindliches Ziel von einem EEG-Mindestanteil von 32 % in diesen Bereichen vereinbart. Die einzelnen Staaten können nun diverse Möglichkeiten nutzen, um dieses Ziel zu erreichen.",
        "In Deutschland ist eine dieser Maßnahmen die Einführung der THG-Minderungszertifikate und der Handel mit diesen. Es ist ein marktwirtschaftlicher Ansatz, der dazu dient, Anreize zu schaffen, Treibhausgaserzeuger durch klimafreundliche Alternativen zu ersetzen. Der deutsche Staat legt hierbei Quoten fest, die den Mindestanteil an treibhausgasfreien Energieanteilen bezeichnet. Vor allem Mineralölindustrie ist hiervon betroffen, diese versucht beispielsweise über E10 Kraftstoff einen Teil dieser Quote zu erreichen. Da der Anteil durch die wenigsten Konzerne, die zum Emissionshandel verpflichtet sind, heute selbst erreicht werden kann, sind sie auf Zukäufe durch den Zertifikatshandel angewiesen. Erreichen die verpflichteten Unternehmen die Quoten nicht, drohen ihnen empfindliche Strafzahlungen.",
        "An dieser Stelle kommt Ihr e-Auto ins Spiel. Um den Wechsel zur E-Mobilität für Privatkunden noch attraktiver zu machen, kann jeder E-Auto-Besitzer ab 2022 jährlich eine gewisse Anzahl an Treibhausgaszertifikaten durch das Umweltbundesamt erhalten.",
        "Da sich die Beantragung und der Handel mit den Zertifikaten für Einzelpersonen nicht lohnt, übernehmen wir dies für Sie. Der gesamte Prozess wird für Sie im Hintergrund abgewickelt. Von der Beantragung Ihrer Zertifikate über den Verkauf der Quoten bis hin zur Auszahlung auf Ihr Bankkonto. Das Einzige, was wir hierfür benötigen, ist der Fahrzeugschein und die Einwilligung von Ihnen, dass wir Ihre THG-Quoten beantragen und vermarkten dürfen."
      ]
    },
    {
      title: "Was passiert mit den Zertifikaten wenn ich sie nicht verkaufe?",
      texts: [
        "In dem Fall, dass Sie die Quote selbst nicht verkaufen bzw. vermarkten lassen, behält sich der Staat , alle diese Quoten selbst zu vermarkten. Nur durch Ihr handeln haben Sie selbst die Hoheit darüber, was mit den Erlösen aus Ihren Fahrzeugquoten passiert."
      ]
    },
    {
      title: "Kann ich die Zertifikate selbst vermarkten?",
      texts: [
        "Theoretisch ja. Es ist jedoch nicht zu empfehlen, da Sie ein zu geringes Volumen vermarkten und daher keinen Abnehmer finden werden."
      ]
    },
    {
      title: "Welche Fahrzeugtypen sind für die Beantragung der Quote zugelassen?",
      texts: [
        "Nur für reine Elektrofahrzeuge (BEVs) dürfen THG-Quoten beantragt werden. Die Plug-in-Hybrid-, Verbrenner-, Erdgas- oder Wasserstofffahrzeuge sind von der Beantragung ausgeschlossen. Wenn Sie sich unsicher sind, prüfen Sie bitte den Fahrzeugschein an der Stelle P.3 hier muss „Elektro“ vermerkt sein, damit Sie von der THG-Quote profitieren können."
      ]
    },
    {
      title: "Welche Bedingungen muss ich erfüllen um die THG-Quoten zu erhalten?",
      texts: [
        "Sie müssen der eingetragene Halter des Elektrofahrzeugs sein, d. h. Sie stehen als Halter im Fahrzeugschein und Ihr Fahrzeug darf dieses Jahr (2024) nicht bereits anderweitig für die THG-Quote vermarktet worden sein. Falls Sie keinen Neuwagen fahren, vergewissern Sie sich bitte, dass der Vorbesitzer die Quote für 2024 nicht bereits beantragt und ausgeschüttet bekommen hat."
      ]
    },
    {
      title: "Wie lange dauert es, bis ich meine Prämie erhalte?",
      texts: [
        "Aufgrund längerer Bearbeitungszeiten beim Umweltbundesamt (Validierung der Anträge) dauert es aktuell mindestens 3 Monate."
      ]
    },
    {
      title: "Wie viel ist meine THG-Minderungsquote wert?",
      texts: [
        "Der wohl wichtigste Treiber für den Erlös der THG-Quote sind die Strafzahlungen aufseiten der Nichteinhaltung, diese betragen aktuell ca. 600 € je Tonne CO². Da ein normales Elektroauto 2024 ein äquivalent von 0,65 Tonnen CO² zugeordnet bekommt, liegt der wohl maximal einlösbare Preis bei circa 416 €. Durch unseren eigenen optimierten Ansatz und die hohe IT sowie Softwarekompetenz profitieren Sie bei uns von geringen Betriebskosten, wodurch Ihre Ausschüttung nur geringfügig geschmälert wird."
      ]
    },
    {
      title: "Kann ich auch andere Fahrzeuge außer PKWs registrieren?",
      texts: [
        "Ja. Jedes Elektrofahrzeug, welches einen Fahrzeugschein (Zulassungsbescheinigung Teil 1) besitz, erhält THG-Quoten. Für leichte Nutzfahrzeuge (klasse N1) erhalten Sie beispielsweise noch einmal 50% mehr Erlös, als für einen PKW."
      ]
    },
    {
      title: "Wie sieht die Prämienausschüttung bei Firmenkunden aus?",
      texts: [
        "Wir verfahren im Gutschriftenverfahren. Sofern ein Gewerbekunde umsatzsteuerpflichtig ist, erfolgt die Auszahlung zuzüglich Umsatzsteuer."
      ]
    },
    {
      title: "Mein Fahrzeug ist geleast, bin ich dennoch berechtigt, die THG-Prämie zu erhalten?",
      texts: [
        "Wenn Sie in der Zulassungsbescheinigung des Fahrzeugs als Halter eingetragen sind, dann dürfen Sie die Prämie beantragen. Falls Ihre Leasinggesellschaft der eingetragene Halter des Fahrzeugs ist, ist leider nur diese antragsberechtigt."
      ]
    },
    {
      title: "Ich habe das Fahrzeug erst jetzt zugelassen, wird meine Prämie anteilig reduziert?",
      texts: [
        "Nein, auch wenn Sie Ihr Fahrzeug erst am 15.11. des Jahres zulassen, besagt die aktuelle Gesetzeslage, dass Sie die volle THG-Prämie erhalten."
      ]
    },
    {
      title: "Ich habe mein Fahrzeug gebraucht gekauft, erhalte ich die THG-Quote dennoch?",
      texts: [
        "Wenn der Vorbesitzer die Quote für dieses Jahr noch nicht beantragt hatte, erhalten Sie die THG-Quote für dieses Jahr. Ansonsten ist Ihr Fahrzeug erst im kommenden Kalenderjahr wieder THG-Quoten berechtigt."
      ]
    },
    {
      title: "Mein Fahrzeug ist nicht in Deutschland zugelassen, erhalte ich dennoch die Prämie?",
      texts: [
        "Leider nein, die THG-Quote ist aktuell ein rein deutsches Instrument zur eMobilitätsförderung."
      ]
    },
    {
      title: "Muss die Quote jährlich beantragt werden?",
      texts: [
        "Ja, die THG-Quote muss jedes Jahr erneut beantragt werden. Wir fragen wir Sie, ob wir Ihre Quote erneut beantragen sollen."
      ]
    },
    {
      title: "Gibt es die THG-Quote auch für Photovoltaikanlagen oder Energiespeicher?",
      texts: [
        "Leider nein, im B2C Bereich gibt es THG-Quoten aktuell nur für die pauschale PKW Anrechnung."
      ]
    },
    {
      title: "Wo kann ich den aktuellen Status einsehen?",
      texts: [
        "Den Status Ihres Auftrages können Sie jederzeit im Kundenportal einsehen. Den Link zum Kundenportal finden Sie in unseren E-Mails."
      ]
    },
    {
      title: "Sie haben keine Auftragsbestätigung von uns erhalten?",
      texts: [
        "Unmittelbar nach Ihrer Registrierung erhalten Sie eine erste Mail von uns, anschließend bestätigen wir Ihren Auftrag innerhalb von 24 Stunden. Sollte Sie keine Mail von uns erhalten haben kann es sein, dass Ihr Mailpostfach voll ist oder Sie sich bei Ihrer Mailadresse vertippt haben. Melden Sie sich in solchen Fällen bitte in unserem Chat oder via Mail" + (this.env?.VARS?.EMAIL ? ` (${this.env?.VARS?.EMAIL}).` : ".")
      ]
    }
  ];

  constructor(
    private wizardService: WizardService,
    private cmsService: CmsReaderService
  ) { }

  ngOnInit(): void {
    this.cmsService.afterInit.subscribe(_ => {
      this.faqs = this.cmsService.faqData.map(el => {
        return { title: el.headline, texts: [el.text] };
      });
    });
  }

  next() {
    this.wizardService.next();
  }

  prev() {
    this.wizardService.prev();
  }
}
