import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'app-agb-legal',
  templateUrl: './agb.component.html',
  styleUrls: ['./agb.component.scss']
})
export class AgbComponent implements OnInit {
  @Input() show = true;

  env = environment;

  constructor() { }

  ngOnInit(): void {
  }

}
