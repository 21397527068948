import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'app-datenschutz-legal',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.scss']
})
export class DatenschutzComponent implements OnInit {
  @Input() show = true;

  env = environment;

  constructor() { }

  ngOnInit(): void {
  }

}
