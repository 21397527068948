import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { AgbComponent } from './shared/legal/agb/agb.component';
import { DatenschutzComponent } from './shared/legal/datenschutz/datenschutz.component';
import { ImpressumComponent } from './shared/legal/impressum/impressum.component';
import { RegisterComponent } from './views/register/register.component';

export const routes: Routes = [
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: "accout_access",
    redirectTo: 'profile/status',
  },
  {
    path: "account_access",
    redirectTo: 'profile/status',
  },
  {
    path: 'login',
    redirectTo: 'home/login'
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'impressum',
    component: ImpressumComponent,
    data: {
      title: 'Impressum Page'
    }
  },
  {
    path: 'agb',
    component: AgbComponent,
    data: {
      title: 'AGB Page'
    }
  },
  {
    path: 'datenschutz',
    component: DatenschutzComponent,
    data: {
      title: 'Datenschutz Page'
    }
  },
  {
    path: 'password_initial',
    redirectTo: 'home/reset'
  },
  {
    path: 'password_reset',
    redirectTo: 'home/reset'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
      },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
