import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WizardService {
  onStepChange: ReplaySubject<any> = new ReplaySubject(1);
  public loaded: boolean = false;

  constructor() { }

  _handleLoaded(cb = null) {
    if(!this.loaded) {
      return;
    }

    this.loaded = false;

    if(cb) {
      cb();
    }
  }

  next() {
    //////console.log("Next!", this.loaded)
    this._handleLoaded(() => {
      //////console.log("TriggerNext");
      this.onStepChange.next({step: 1});
    });
  }

  prev() {
    this._handleLoaded(() => {
      this.onStepChange.next({step: -1});
    });
  }

  reset() {
    this.onStepChange.next({done: true})
  }

  error() {
    this.onStepChange.next({error: true})
  }
}
