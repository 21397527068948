import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AnalyticsService } from '../../services/google/analytics.service';

@Directive({
  selector: '[a_click]'
})
export class AnalyticsDirective implements OnInit {
  @Input() a_action: string = "";
  @Input() a_category: string = "";
  @Input() a_label: string = "";
  @Input() a_value: number | null = null;

  constructor(
    private el: ElementRef,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    if (!this.el?.nativeElement) {
      return;
    }

    this.el.nativeElement.onclick = (e: MouseEvent) => {
      if(!(this.a_action && this.a_category)) {
        return;
      }

      this.analyticsService.emit(
        this.a_action,
        this.a_category,
        this.a_label,
        this.a_value
      )
    };
  }
}
