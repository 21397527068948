import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@autocash-core';
import { HomeComponent } from './home/home.component';
import { SenecRoutingModule } from './senec.routing';
import { MarketingComponent } from './marketing/marketing.component';
import { AgbComponent } from './agb/agb.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { RegisterFormComponent } from './register-form/register-form.component';
import { CustomService } from '@autocash-core';
import { RegisterSuccessComponent } from './register-success/register-success.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [
    HomeComponent,
    MarketingComponent,
    AgbComponent,
    ImpressumComponent,
    DatenschutzComponent,
    RegisterFormComponent,
    RegisterSuccessComponent,
    FooterComponent
  ],
  imports: [
    SenecRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CoreModule
  ],
  exports: [
  ]
})
export class SenecModule {
  constructor(
    private customService: CustomService
  ) {
    this.customService.register("custom-fields", RegisterFormComponent);
    this.customService.register("marketing-small", MarketingComponent);
    this.customService.register("register-success", RegisterSuccessComponent);
    this.customService.register("agb", AgbComponent);
    this.customService.register("impressum", ImpressumComponent);
    this.customService.register("datenschutz", DatenschutzComponent);
  }
}
