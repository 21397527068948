import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AffiliateService, CmsReaderService, CoreModule, CustomService } from '@autocash-core';
import { HomeComponent } from './home/home.component';
import { EvCashbackRoutingModule } from './ev-cashback.routing';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { CompileDirective } from './directives/compile.directive';
import { RegisterFormComponent } from './register-form/register-form.component';
import { AboutThgComponent } from './about-thg/about-thg.component';
import { AgbComponent } from './agb/agb.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { MarketingComponent } from './marketing/marketing.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';


@NgModule({
  declarations: [
    HomeComponent,
    AboutThgComponent,
    NavComponent,
    FooterComponent,
    CompileDirective,
    RegisterFormComponent,
    AgbComponent,
    ImpressumComponent,
    MarketingComponent,
    DatenschutzComponent
  ],
  imports: [
    EvCashbackRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CoreModule
  ],
  exports: [
    HomeComponent
  ]
})
export class EvCashbackModule {
  constructor(
    private customService: CustomService,
    private cmsService: CmsReaderService,
    private affiliateService: AffiliateService
  ) {

    const companyId = this.affiliateService.getCompanyId();
    if(companyId) {
      (this.affiliateService.config.pages || []).forEach(page => {
        this.cmsService.loadPages(page.id, [page.name]);
      });

      this.cmsService.loadFAQ(companyId);
      this.cmsService.loadCompany(companyId);
    }

    this.customService.register("custom-fields", RegisterFormComponent);
    this.customService.register("marketing", MarketingComponent);
    this.customService.register("datenschutz", DatenschutzComponent);
    this.customService.register("impressum", ImpressumComponent);
    this.customService.register("agb", AgbComponent);
  }
}
