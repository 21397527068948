import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegistrationService } from '../../services/registration.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent implements OnInit {

  theme = "";

  constructor(
    private register: RegistrationService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        const { th, fn, ln, e, c } = params;
        this.theme = th || "";
        this.register.pushUserData({fn, ln, e, c});
      });
  }
}
