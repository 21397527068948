import { Component, OnInit } from '@angular/core';
import { CmsReaderService, CmsService, FormService } from '@autocash-core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {
  formData: any[] = [];
  data: any = {};

  constructor(
    private cmsService: CmsReaderService,
    private formService: FormService
  ) { }

  ngOnInit(): void {
    this.cmsService.afterInit.subscribe(_ => {
      this.formData = this.cmsService.formData.filter(d => !!d.valid);
    });
  }

  onChange() {
    this.formService.customUserInfo(this.data);
  }
}
