import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule, ErrorHandler, Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { LocationStrategy, HashLocationStrategy, registerLocaleData, CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { CoreComponent } from './core.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { RegisterComponent } from './views/register/register.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { CoreRoutingModule } from './core.routing';

import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './services/http-interceptor.service';

import { NotificationComponent } from './views/notification/notification.component';
import { SharedModule } from './shared/shared.module';

import * as Sentry from "@sentry/angular";
import localeDe from '@angular/common/locales/de';
import { StorageService } from './services/storage.service';

registerLocaleData(localeDe);

@Injectable()
class HashLocationStrategyQueryPreserve extends HashLocationStrategy {
  storageService: StorageService = new StorageService();

  _searchToObject() {
    var pairs = window.location.search.substring(1).split("&"),
      obj = {},
      pair,
      i;

    for (i in pairs) {
      if (pairs[i] === "") continue;

      pair = pairs[i].split("=");
      obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }

    return obj;
  }

  prepareExternalUrl(internal: string): string {
    if (window.location.search) {
      this.storageService.session.setItem("eREF", JSON.stringify(this._searchToObject()));
    }

    return super.prepareExternalUrl(internal);
  }
}

const COMPONENTS = [
  CoreComponent,
  P404Component,
  P500Component,
  RegisterComponent,
  NotificationComponent
]

const MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  CoreRoutingModule,
  AppAsideModule,
  AppBreadcrumbModule.forRoot(),
  SharedModule,
  AppFooterModule,
  AppHeaderModule,
  AppSidebarModule,
  PerfectScrollbarModule,
  ChartsModule,
  IconModule,
  IconSetModule.forRoot(),
  FormsModule,
  HttpClientModule,
  ReactiveFormsModule
];

@NgModule({
  imports: [
    ...MODULES
  ],
  declarations: [
    ...APP_CONTAINERS,
    ...COMPONENTS,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "de" },
    /*{
      provide: LocationStrategy,
      useClass: HashLocationStrategyQueryPreserve
    },*/
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: true,
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    IconSetService
  ],
  exports: [
    ...COMPONENTS,
    SharedModule
  ]
})
export class CoreModule { }
