import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '@env';
import { CmsService } from '../../services/cms.service';

@Component({
  selector: 'app-faq-selector',
  templateUrl: './faq-selector.component.html',
  styleUrls: ['./faq-selector.component.scss']
})
export class FaqSelectorComponent implements OnInit {
  _base = environment.BACK_URL;

  allFAQs = [];
  newFaq: any = {};

  constructor(
    private cms: CmsService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.cms.afterInit.subscribe(_ => {
      this.allFAQs = this.cms.faqData;
      this.onChange();
    });
  }

  onChange() {
    this.cms.faqData = this.allFAQs;
  }

  saveNewFaq() {
    this.http.post(`${this._base}/cms/v1/faq`, [this.newFaq]).subscribe((d: any[]) => {
      const unsentFAQ = { active: false, order: 0, def_faq_id: d[0].id };
      
      this.http.post(`${this._base}/cms/v1/affiliate_company/faq`, [unsentFAQ]).subscribe((d2: any[]) => {
        this.newFaq = {};
        d[0].affiliate_company_faq_id = d2[0].id;
        this.allFAQs = [...this.allFAQs, ...d];
      });
      
      this.onChange();
    });
  }
}
