import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { FormService } from '../../../services/form.service';
import { AnalyticsService } from '../../../services/google/analytics.service';
import { NotificationService } from '../../../services/notification.service';
import { RegistrationService } from '../../../services/registration.service';
import { RestService } from '../../../services/rest.service';
import { StorageService } from '../../../services/storage.service';
import { WizardService } from '../../../services/wizard.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnDestroy {
  email: string = "";
  agb: boolean = false;
  privacyPolicy: boolean = false;

  env = environment;
  showTitle: boolean = !!environment?.PROCESS?.TITLE;

  profileForm = new FormGroup({
    title: new FormControl(""),
    company: new FormControl("", [Validators.required]),
    firstname: new FormControl("", [Validators.required]),
    lastname: new FormControl("", [Validators.required])
  });

  get title() {
    return this.profileForm.get('title');
  }

  get company() {
    return this.profileForm.get('company');
  }

  get firstname() {
    return this.profileForm.get('firstname');
  }
  get lastname() {
    return this.profileForm.get('lastname');
  }

  subs: Subscription[] = [];

  constructor(
    private auth: AuthService,
    private register: RegistrationService,
    private wizardService: WizardService,
    private formService: FormService,
    private note: NotificationService,
    private rest: RestService,
    private analytics: AnalyticsService,
    private storage: StorageService
  ) { }

  canStore = false;
  tmpData = {};
  store(data, key) {
    if (!this.canStore) {
      return;
    }

    this.tmpData = { ...this.tmpData, [key]: data };
    this.storage.session.setItem("userdetails", JSON.stringify(this.tmpData));
  }

  ngOnInit(): void {
    let { email, agb, privacyPolicy, firstName, lastName, company } = JSON.parse(this.storage.session.getItem("userdetails") || "{}");
    if (email || agb || firstName || lastName || company) {
      this.firstname.setValue(firstName || "");
      this.lastname.setValue(lastName || "");
      this.company.setValue(company || "");

      this.email = email || "";
      this.agb = agb || false;
      this.privacyPolicy = privacyPolicy || false;

    };

    setTimeout(() => {
      this.tmpData = {
        email: this.email,
        agb: this.agb,
        privacyPolicy: this.privacyPolicy,
        firstName: this.firstname.value,
        lastName: this.lastname.value,
        company: this.company.value
      };

      this.storage.session.setItem("userdetails", JSON.stringify(this.tmpData));
      this.canStore = true;
    }, 200);

    this.subs.push(this.register.onUserData
      .subscribe(params => {
        const firstName = params?.fn || "";
        const lastName = params?.ln || "";
        const company = params?.c || "";

        if (firstName) {
          this.firstname.setValue(firstName);
          this.store(firstName, "firstName");
        }
        if (lastName) {
          this.lastname.setValue(lastName);
          this.store(lastName, "lastName");
        }
        if (company) {
          this.company.setValue(company);
          this.store(company, "company");
        }
      }));

    this.subs.push(this.auth.onSignupChange.subscribe(token => {
      if (!token) {
        return;
      }

      this.wizardService.next();
    }));

    this.subs.push(this.formService.onChange.subscribe(data => {
      const { email, agb, privacyPolicy } = data;
      if (agb !== undefined) {
        this.agb = agb;
        this.store(agb, "agb");
      }

      if (privacyPolicy !== undefined) {
        this.privacyPolicy = privacyPolicy;
        this.store(privacyPolicy, "privacyPolicy");
      }

      if (email !== undefined) {
        this.email = email;
        this.store(email, "email");
      }
    }));

    this.subs.push(this.formService.onValidate.subscribe(data => {
      if (!data.firstName) {
        this.firstname.markAsTouched();
      }

      if (!data.lastName) {
        this.lastname.markAsTouched();
      }

      if (!data.company) {
        this.company.markAsTouched();
      }
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  _handleError(err) {
    this.wizardService.error();
    this.note.error("Hier ist wohl was schief gelaufen")
  }

  onUserInfo(company, firstname, lastname) {
    const title = this.showTitle ? this.title.value : "";
    ////////console.log("title = ", title);
    this.formService.answerUserInfo(company, firstname, lastname, title);

    if (!this.formService.validate()) {
      return;
    }

    this.formService.validateUser(() => {
      this.auth.signup(this.formService.userData).subscribe(
        _ => {
          this.analytics.emit("register_form_signup", "request", "User registriert");
          this.auth.requestEmail(this.formService.userData.email).subscribe(
            _ => {
              this.rest.holder(this.formService.userData).subscribe(_ => {
                this.wizardService.next();
              });
            }
          )
        },
        this._handleError.bind(this)
      )
    });
  }

  prev() {
    this.wizardService.prev();
  }
}
