import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { RenewService } from '../../services/renew.service';
import { RestService } from '../../services/rest.service';
import { WizardService } from '../../services/wizard.service';

@Component({
  selector: 'app-extend-vehicle',
  templateUrl: './extend-vehicle.component.html',
  styleUrls: ['./extend-vehicle.component.scss']
})
export class ExtendVehicleComponent implements OnInit, OnDestroy {
  renewData: any[] = [];
  subs: Subscription[] = [];

  @Input() inWorkflow: boolean = false;

  constructor(
    private rest: RestService,
    private renew: RenewService,
    private wizardService: WizardService,
    private notice: NotificationService
  ) { }

  ngOnInit(): void {
    this.subs.push(this.renew.renewData$.subscribe((data: any[]) => {
      this.renewData = data;
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  _selectAll: boolean = false;
  set selectAll(a: boolean) {
    this._selectAll = a;
    this.renewData.forEach(rd => {
      rd.offer.accept = a;
    });
  }

  get selectAll() {
    return this._selectAll;
  }

  onRenewChange() {
    this._selectAll = true;
    for (const rd of this.renewData) {
      if (!rd?.offer?.accept) {
        this._selectAll = false;
        return;
      }
    }
  }

  public setSelectAll(a: boolean) {
    this.selectAll = a;
  }

  close() {
    window.location.reload();
  }

  renewAll() {
    this.setSelectAll(true);
    this.save();
  }

  public renewSelected(cb = null) {
    const accept = this.renewData.filter(rd => rd?.offer?.accept).map(obj => obj?.annual_user_payout_id);
    const decline = this.renewData.filter(rd => !rd?.offer?.accept).map(obj => obj?.annual_user_payout_id);
    if (accept.length == 0) {
      this.notice.error("Sie haben keine Fahrzeuge ausgewählt. Bitte nutzen Sie den Schalter an den Fahrzeugen, um die Fahrzeuge, welche Sie verlängern wollen, auszuwählen.")
      return;
    }

    this.rest.nextYear(accept, decline).subscribe(_ => {
      this.selectAll = false;
      cb?.(true);
    }, _ => {
      this.selectAll = false;
      cb?.(false);
    });
  }

  prev() {
    this.wizardService.prev();
  }

  save() {
    this.renewSelected((success) => {
      if (success) {
        this.notice.success("Die Fahrzeuge wurden verlängert.", () => {
          window.location.reload();
        })
        return;
      }

      this.notice.error("Beim speichern Ihrer Auswahl ist etwas schief gelaufen. Bitte versuchen Sie es erneut.");
    });
  }
}
