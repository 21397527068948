import { ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { environment } from '@env';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { CmsReaderService } from '../../services/cms-reader.service';
import { NotificationService } from '../../services/notification.service';
import { ThemeService } from '../../services/theme.service';
import { navItems } from '../../_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
  footer: string = "Powered by NYNEX";
  public sidebarMinimized = false;
  public navItems = [];

  env = environment;

  subs: Subscription[] = [];

  get canLogin() {
    return this.auth.canLogin;
  }

  _editMode = false;
  set editMode(e: boolean) {
    this._editMode = e;
  }

  get editMode() {
    return this.canLogin && this._editMode;
  }

  constructor(
    private auth: AuthService,
    public themeService: ThemeService,
    private cmsService: CmsReaderService,
    private note: NotificationService,
    private cdref: ChangeDetectorRef
  ) { }

  makeAccount() {
    this.auth.secureLogin.init().then(_ => {
      this.note.success("Bitte prüfen Sie Ihre E-Mails für weitere Schritte!");
    }).catch(_ => {
      this.note.error("Leider ist etwas schief gelaufen.");
    });
  }

  saveProfileData() {
    this.auth.onSaveProfile$.next();
  }

  logout() {
    this.auth.logout(false);
  }

  ngOnInit(): void {
    this.subs.push(
      this.auth.onPermissionChange.subscribe(() => {
        this.editMode = this.auth.isPasswordLogin;

        this.navItems = navItems.filter(item => {
          if ((item?.url || "").includes("/affiliate")) {
            return this.auth.canAffiliate;
          }

          if ((item?.url || "").includes("/referal")) {
            return this.auth.canRef;
          }

          if ((item?.url || "").includes("/b2b")) {
            return this.auth.canB2b;
          }

          if ((item?.url || "").includes("/payout")) {
            return this.auth.canPayout;
          }

          if ((item?.url || "").includes("/chargepoint")) {
            return this.auth.canChargingstation;
          }

          return true;
        });

        this.cdref.detectChanges();
      })
    );

    this.subs.push(
      this.cmsService.afterInit.subscribe(_ => {
        const { footer } = this.cmsService.companyData;

        this.footer = footer;
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}
