import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AffiliateService, ClientService } from '@autocash-core';
import { environment } from '@env';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  env = environment;

  constructor(
    private clientService: ClientService,
    private affiliateService: AffiliateService
  ) {}

  get payoutFixed() {
    return this.affiliateService.payoutFixed;
  }

  get payoutText() {
    return this.affiliateService.payoutText;
  }

  get payoutPercentage() {
    return this.affiliateService.payoutPercentage;
  }

  scrollTo(id: string) {
    $(id)[0]?.scrollIntoView();
  }

  ngOnInit(): void {
    this.clientService.setType("single");
    if(window?.location?.pathname?.includes("home/login")) {
      $('#loginModal').modal('show');
    } else if(window?.location?.pathname?.includes("home/reset")) {
      $('#pwresetModal').modal('show');
    }
  }
}
