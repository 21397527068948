import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Subscription } from 'rxjs';
import { ExternalScriptService } from '../external-script.service';
import { StorageService } from '../storage.service';

var gtag;

const STORAGE_KEY = "autocash-cookies";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  subs: Subscription[] = [];
  _canAnalyze: boolean = false;
  set canAnalyze(ca: boolean) {
    this._canAnalyze = ca;

    if (ca) {
      this.storageService.local.setItem(STORAGE_KEY, "accept");
    }
  }

  get canAnalyze() {
    return this._canAnalyze;
  }

  constructor(
    private storageService: StorageService,
    private externalScript: ExternalScriptService
  ) {
    if (this.storageService.local.getItem(STORAGE_KEY) === 'accept') {
      this.canAnalyze = true;
      setTimeout(() => {
        this.start(true);
      }, 500);
    }
  }

  start(force = false) {
    if (this.canAnalyze && !force) {
      return;
    }

    if (environment.GOOGLE_ANALYTICS_PROPERTY) {
      this.externalScript.loadExternalScript('https://www.googletagmanager.com/gtag/js?id=' + environment.GOOGLE_ANALYTICS_PROPERTY);
    }

    if (environment.META_PIXEL_ID) {
      (function (f: any, b, e, v, n, t, s) {
        if (f.fbq) return; n = f.fbq = function () {
          n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }; if (!f._fbq) f._fbq = n;
        n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      //(window as any).fbq.disablePushState = true; // set to true to stop sending PageView events on history state changes
      (window as any).fbq('init', environment.META_PIXEL_ID);
      (window as any).fbq('track', 'PageView');
      ////////console.log('Facebook pixel init run!')
    }

    setTimeout(() => {
      this.canAnalyze = true;
      dispatchEvent(new Event('load'));

      if (environment.GOOGLE_ANALYTICS_PROPERTY) {
        (window as any).dataLayer = (window as any).dataLayer || [];
        gtag = function () {
          (window as any).dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', environment.GOOGLE_ANALYTICS_PROPERTY);
      }
    }, 1000);
  }

  public emit(
    eventAction: string,
    eventCategory: string,
    eventLabel: string = null,
    eventValue: number = null) {
    if (!this.canAnalyze) {
      return;
    }

    if (environment.GOOGLE_ANALYTICS_PROPERTY && gtag) {
      gtag('event', eventAction, {
        "event_category": eventCategory,
        "event_label": eventLabel,
        "value": eventValue
      })
    }

    const fbq = (window as any).fbq;
    if (environment.META_PIXEL_ID && fbq) {
      fbq('track', 'ViewContent', {
        content_name: eventAction,
        content_category: eventCategory,
        content_ids: eventLabel,
        content_type: eventCategory,
        value: eventValue,
      });
    }

  }
}
