import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  onTitle: ReplaySubject<string> = new ReplaySubject(1);
  onType: ReplaySubject<string> = new ReplaySubject(1);
  onMessage: ReplaySubject<string> = new ReplaySubject(1);

  onClose = () => {};

  constructor() { }

  success(msg: string, onClose = () => {}) {
    this.onClose = onClose;
    this.onMessage.next(msg);
    this.onType.next("success");
    this.onTitle.next("Erfolg");
  }

  error(msg: string, onClose = () => {}) {
    this.onClose = onClose;
    this.onMessage.next(msg);
    this.onType.next("error");
    this.onTitle.next("Fehler");
    Sentry.captureException(new Error(msg), {});
  }

  warning(msg: string, onClose = () => {}) {
    this.onClose = onClose;
    this.onMessage.next(msg);
    this.onType.next("warning");
    this.onTitle.next("Warnung");
    Sentry.captureException(new Error(msg), {});
  }
}
