import { Component, OnInit } from '@angular/core';
import { CmsReaderService, CmsService } from '@autocash-core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {
  template: string = "";

  constructor(
    private cmsService: CmsReaderService
  ) { }

  ngOnInit(): void {
    this.cmsService.afterInit.subscribe(_ => {
      const html = this.cmsService.getCustomPageData("impressum").html || ""
      this.template = html;
    });
  }
}
