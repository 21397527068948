import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '@env';
import { AffiliateService } from '../../services/affiliate.service';
import { CmsService } from '../../services/cms.service';

@Component({
  selector: 'app-payment-option-selector',
  templateUrl: './payment-option-selector.component.html',
  styleUrls: ['./payment-option-selector.component.scss']
})
export class PaymentOptionSelectorComponent implements OnInit {
  _base = environment.BACK_URL;

  payoutOptions = [];
  usedPayoutOptions = [];

  newPayoutOption: any = {};

  constructor(
    private cms: CmsService,
    private http: HttpClient,
    private affiliateService: AffiliateService
  ) { }

  ngOnInit(): void {
    this.cms.afterInit.subscribe(_ => {
      this.payoutOptions = this.cms.payoutData;
      this.onChange();
    });
  }

  onChange() {
    this.cms.payoutData = this.payoutOptions;
  }

  saveNewPayoutOption() {
    const codeId = this.affiliateService.getCodeId();
    if(codeId === null) {
      return;
    }

    this.newPayoutOption.affiliate_code_id = codeId;
    this.http.post(`${this._base}/cms/v1/def_payout_option`, [this.newPayoutOption]).subscribe((d: any) => {
      this.newPayoutOption = {};
      this.payoutOptions = [...this.payoutOptions, ...d];
      this.onChange();
    });
  }
}
