import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

import { environment } from '@env';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  successMsg: string = "";
  errorMsg: string = "";

  get prefill() {
    return {
      uname: environment?.login?.default_uname || "",
      pw: environment?.login?.default_password || ""
    }
  }

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  code: string = "";
  userId: string = "";
  username: string = "";

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.code = params.code;
        this.userId = params.id;
        this.username = params.email || "";
      });
  }

  register() {

  }

  login(name, pw) {
    if (this.canReset) {
      this.auth.secureLogin.revover(name)
        .then(() => {
          this.successMsg = "Bitte prüfen Sie Ihre E-Mails für weitere Schritte!"
        }).catch(() => {
          this.errorMsg = "Leider ist etwas schief gelaufen."
        })
      return;
    }

    this.auth.secureLogin.login(name, pw)
      .then(() => {
        this.router.navigate(["/profile/status"], {
          queryParamsHandling: 'merge',
          queryParams: { id: null, code: null, email: null },
        }).then(_ => {
          window.location.reload();
        });
      }).catch(() => {
        this.errorMsg = "E-Mail oder Passwort ist nicht korrekt."
      })
  }

  request(name) {
    this.auth.secureLogin.init().then(_ => {
      this.successMsg = "Bitte prüfen Sie Ihre E-Mails für weitere Schritte!"
    }).catch(_ => {
      this.errorMsg = "Leider ist etwas schief gelaufen."
    });
  }

  canReset: boolean = false;
  reset(uname) {
    if (!this.canReset) {
      this.canReset = true;
      return;
    }

    this.canReset = false;
  }
}
