import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AuthService } from '@autocash-core';

@Component({
  selector: 'app-custom-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  isScrolled: boolean = false;

  @Input() className = 'default';

  constructor(
    private auth: AuthService
  ) { }

  get canLogin() {
    return this.auth.canLogin;
  }

  ngOnInit(): void {
  }

  @HostListener('document:scroll', ['$event'])
  onScroll(event) {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    this.isScrolled = top >= 40;
  }
}
