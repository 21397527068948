import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  userData: any = {};
  bankData: any = {
    name: '',
    iban: '',
    bank: ''
  };
  benefitData: any = {};

  constructor(
    private rest: RestService
  ) { }

  onBank: ReplaySubject<any> = new ReplaySubject(1);
  onChange: ReplaySubject<any> = new ReplaySubject(1);
  onValidate: ReplaySubject<any> = new ReplaySubject(1);

  answerAGB(agb: boolean = false) {
    this.userData = {
      ...this.userData,
      agb
    }

    this.onChange.next({
      agb
    });
  }

  answerPrivacyPolicy(privacyPolicy: boolean = false) {
    this.userData = {
      ...this.userData,
      privacyPolicy
    }

    this.onChange.next({
      privacyPolicy
    });
  }

  answerMarketing(marketing: boolean = false) {
    this.userData = {
      ...this.userData,
      marketing
    }

    this.onChange.next({
      marketing
    });
  }

  answerUserEmail(email) {
    this.userData = {
      ...this.userData,
      email
    }

    this.onChange.next({
      email
    });
  }

  customUserInfo(data: any) {
    this.userData = {
      ...this.userData,
      extras: data
    }
  }

  answerUserInfo(company, firstname, lastname, title = "") {
    this.userData = {
      ...this.userData,
      title,
      company,
      first_name: firstname,
      surname: lastname,
    }

    this.rest.hasCompany = !!company;
  }

  validate(bank = false) {
    if (!bank) {
      const validate = {
        agb: !!this.userData.agb,
        privacyPolicy: !!this.userData.privacyPolicy,
        email: !!this.userData.email,
        firstName: !!this.userData.first_name,
        lastName: !!this.userData.surname,
        company: !!this.userData.company
      };

      this.onValidate.next(validate);

      return validate.agb
        && validate.privacyPolicy !== false
        && validate.email
        && (validate.company || (validate.firstName && validate.lastName));
    }

    const validate = {
      name: !!this.bankData.name,
      bank: !!this.bankData.bank,
      iban: !!this.bankData.iban,
    }

    this.onValidate.next(validate);

    return (validate.name && validate.bank && !!this.isValidIBANNumber(this.bankData.iban) || this.emptyBank)
  }

  get emptyBank() {
    return !(this.bankData.name || this.bankData.bank || this.bankData.iban)
  }

  validateUser(success = () => { }) {
    if (this.userData.email && this.userData.agb) {
      success();
    }
  }

  answerBankInfo(
    name, bank, iban
  ) {
    this.bankData = {
      ...this.bankData,
      name,
      bank,
      iban
    }

    this.onBank.next(this.bankData);
  }

  answerBenefitOption(option: number = 0, amount: number = 0) {
    this.benefitData = {
      option,
      amount
    }
  }

  isValidIBANNumber(input) {
    function mod97(string) {
      var checksum = string.slice(0, 2), fragment;
      for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
      }
      return checksum;
    }

    var CODE_LENGTHS = {
      AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
      CH: 21, CR: 22, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
      FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
      HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
      LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
      MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
      RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
      AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
      SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20, LY: 25,
    };
    var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
      code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
      digits;
    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
      return false;
    }

    //@ts-ignore
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter: string) => {
      return letter.charCodeAt(0) - 55;
    });
    // final check
    return (mod97(digits) === 1);
  }

  IBANValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !this.isValidIBANNumber(control.value) ? { invalidIban: { value: control.value } } : null;
    };
  }
}
