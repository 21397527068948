import { Injectable } from '@angular/core';
import { environment } from '@env';
import { AffiliateService } from './affiliate.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  _theme: string = "";
  images = {
    logo: {
      src: "assets/e-autocash/logo_v3.svg",
      width: 125,
      height: 25
    },
    background: {
      src: "url('/assets/e-autocash/home.png')"
    }
  }

  constructor() {
    if (environment.brand) {
      this.images = environment.brand;
    }
  }

  initTheme(customEnv) {
    const logoimage = customEnv?.affiliate_company?.logo_image?.name_unique;
    const backgroundimage = customEnv?.affiliate_company?.background_image?.name_unique

    if (logoimage) {
      this.images.logo.src = 'assets/dbimages/' + logoimage;
    }

    if (backgroundimage) {
      this.images.background.src = 'assets/dbimages/' + backgroundimage;
    }
  }
}
