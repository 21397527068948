import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss']
})
export class ModalsComponent implements OnInit, OnDestroy {
  @Input() id: string = "";
  @Input() reloadOnClose: boolean = false;

  @Input() showFooter: boolean = true;
  @Input() closeButtonFirst: boolean = false;

  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      $('#'+this.id).on("hidden.bs.modal", () => {
        this.close.emit(true);
      });
    }, 500)
  }

  ngOnDestroy(): void {
    $('#'+this.id).off("hidden.bs.modal");
  }

  reload() {
    this.close.emit(true);
    if (!this.reloadOnClose) {
      return;
    }

    window.location.reload();
  }
}
