import { Component, HostListener, OnDestroy, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClientService } from '../../../services/client.service';
import { DeviceService } from '../../../services/device.service';
import { FullscreenService } from '../../../services/fullscreen.service';
import { ImageService } from '../../../services/image.service';
import { NotificationService } from '../../../services/notification.service';
import { RestService } from '../../../services/rest.service';
import { WizardService } from '../../../services/wizard.service';
import { FileUploadComponent } from '../../file-upload/file-upload.component';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit, OnDestroy {
  @ViewChild("fileUploadFront", { static: false })
  public uploadFront: ElementRef<FileUploadComponent>;
  @ViewChild("fileUploadBack", { static: false })
  public uploadBack: ElementRef<FileUploadComponent>;
  @ViewChild("fileUploadId", { static: false })
  public uploadIdentification: ElementRef<FileUploadComponent>;

  fullscreen: boolean = false;

  hasFront: boolean = false;
  hasBack: boolean = false;
  hasIdentification: boolean = false;

  isDone: boolean = false;
  subs: Subscription[] = [];

  identificationNeeded: boolean = false;

  wantedTypes = ["front", "back"];

  _overlayUrl: any = null;
  get overlayURL() {
    return this._overlayUrl;
  }

  constructor(
    private rest: RestService,
    private wizardService: WizardService,
    private fullscreenService: FullscreenService,
    private deviceService: DeviceService,
    private note: NotificationService,
    private imageService: ImageService,
    private clientService: ClientService
  ) { }

  ngOnInit(): void {
    this.identificationNeeded = this.rest.identificationNeeded;
    if (this.identificationNeeded) {
      this.wantedTypes.push("identification");
    }

    this.hasFront = false;
    this.hasBack = false;
    this.hasIdentification = false;
    this.isDone = false;

    this.subs.push(this.fullscreenService.onFullscreenChange.subscribe(isFull => {
      this.fullscreen = isFull;
    }));

    this._checkFullscreen();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this._checkFullscreen();
  }

  get isMobile() {
    return this.deviceService.isMobile;
  }

  _checkFullscreen(force = false) {
    if (force
      || this.deviceService.isMobile) {
      if (this.deviceService.isHorizontal) {
        this.fullscreenService.fullscreen(true);
        return;
      }
    }

    this.fullscreenService.fullscreen(false);
  }

  _makeFormData(type, data, fileName) {
    let formData = new FormData();
    formData.append("image", data, fileName);
    formData.append("side", type);

    return formData;
  }

  _onResponse(type: string, success: boolean) {
    this.loading = false;

    switch (type) {
      case "front":
        this._doUploadFront = () => { }
        if (!this.uploadFront) {
          break;
        }

        //@ts-ignore
        //this.uploadFront.reset();
        break;
      case "back":
        this._doUploadBack = () => { }
        if (!this.uploadBack) {
          break;
        }

        //@ts-ignore
        //this.uploadBack.reset();
        break;
      case "identification":
        this._doUploadIdentification = () => { }
        if (!this.uploadIdentification) {
          break;
        }

        //@ts-ignore
        //this.uploadIdentification.reset();
        break;
    }

    const index = this.wantedTypes.indexOf(type, 0);
    if (index > -1) {
      this.wantedTypes.splice(index, 1);
    }

    if (!success) {
      this.note.error("Ups, da ist wohl was schief gelaufen. Versuchen Sie es noch einmal")
    } else {
      if (this.wantedTypes.length === 0) {
        this.isDone = true;
      }

      //this.finish();
    }
  }

  loading: boolean = false;

  get isAndroid() {
    const userAgent = navigator.userAgent || navigator.vendor || (<any>window).opera || "";
    return /android/i.test(userAgent)
  }

  newImage(form) {
    this.hasBack = false;
    this.hasFront = false;
    this.hasIdentification = false;
    this.isDone = false;
    form.reset();
  }

  _doUploadFront = () => { }
  _doUploadBack = () => { }
  _doUploadIdentification = () => { }

  onImage(images: any[], force = false, type: string = "front") {
    if (images.length !== 1) {
      this._doUploadFront = () => { };
      this._doUploadBack = () => { };
      this._doUploadIdentification = () => { };
      return;
    }

    const data = this._makeFormData(type, this.imageService.base64ToBlob(images[0].data), images[0].name);
    if (!data) {
      return;
    }

    const cb = () => {
      this.loading = true;
      this.rest.upload(data).subscribe(res => {
        this._onResponse(type, true);
      }, err => {
        this._onResponse(type, false);
      });
    }

    switch (type) {
      case "front":
        //this._doUploadFront = cb;
        cb();
        this.hasFront = true;
        break;
      case "back":
        //this._doUploadBack = cb;
        cb();
        this.hasBack = true;
        break;
      case "identification":
        //this._doUploadIdentification = cb;
        cb();
        this.hasIdentification = true;
        break;
    }

    if (force) {
      this.execureAll();
    }
  }

  finish() {
    if (this.loading || !this.isDone) {
      return;
    }

    this.rest.registerComplete();
    this.wizardService.reset();
  }

  prev() {
    if (this.loading) {
      return;
    }

    this.wizardService.prev();
    this.rest.deleteAnnual(_ => { });
  }

  next() {
    if (this.loading) {
      return;
    }

    //this.execureAll();
    this.finish();
  }

  execureAll() {
    this._doUploadFront();
    this._doUploadBack();
    this._doUploadIdentification();
  }

  clickUpload() {
    if (!this.uploadIdentification) {
      return;
    }
    //@ts-ignore
    this.uploadIdentification.click()
  }

  switch() {
    this.clientService.switchType();
  }
}
