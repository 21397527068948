import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, ReplaySubject, Subject } from 'rxjs';
import { environment } from '@env';
import { filter, map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RestService {
  _base = environment.BACK_URL;

  hasBank = true;
  newVehicleOnNextRequest = false;

  public hasCompany = false;
  public identificationNeeded = false;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }

  affiliate = {
    _mapData: (data: any) => {
      return [
        {
          title: "Registrierte Benutzer",
          value: data?.registered_users || 0,
          color: "success"
        },
        {
          title: "Gültige Fahrzeuge",
          value: data?.valid_vehicles || 0,
          color: "success"
        },
        {
          title: "Fahrzeuge in Bearbeitung",
          value: (data?.validation_pending_vehicles || 0) + (data?.pending_vehicles || 0),
          color: "warning",
          textColor: "dark"
        },
        {
          title: "Ungültige Fahrzeuge",
          value: data?.invalid_vehicles || 0,
          color: "danger"
        },
      ];
    },
    getAffiliateCodes: () => {
      return this.http.get(`${this._base}/v1/affiliate/agent/statistics/codes`).pipe(
        map((data: any) => {
          return Object.keys(data).map(code => {
            return {
              code,
              value: this.affiliate._mapData(data[code])
            }
          });
        })
      )
    },
    getAffiliatePayouts: () => {
      return this.http.get(`${this._base}/v1/affiliate/agent/log/payouts`);
    },
    getReferralPayouts: () => {
      return this.http.get(`${this._base}/v1/referral/log/payouts`);
    },
    getReferralBasic: () => {
      return this.http.get(`${this._base}/v1/referral/statistics/basic`).pipe(
        map((data: any) => {
          return this.affiliate._mapData(data)
        })
      )
    }
  }

  nextYear(accept = [], decline = {}) {
    return this.http.post(`${this._base}/v1/annual_payment_option/next_year_offer`, { accept, decline })
  }

  donations() {
    return this.http.get(`${this._base}/v1/donations`);
  }

  getSepa() {
    return this.http.get(`${this._base}/v1/annual_payment_option/sepa_transfer_entry`)
  }

  downloadInvoice(id: number) {
    return this.http.get(`${this._base}/v1/annual_payment_option/sepa_transfer_entry/${id}/download`, { responseType: 'blob' });
  }

  co2Assignments() {
    return this.http.get(`${this._base}/v1/user/annual_payment_options/co2_assignments`);
  }

  newVehicle() {
    return this.http.post(`${this._base}/v1/vehicle`, {});
  }

  sendRevoke(vehicleId, paymentId) {
    return this.http.post(`${this._base}/v1/vehicle/${vehicleId}/annual_payment_option/${paymentId}/revocation_waived`, {});
  }

  sendRegistrationComplete(paymentIds) {
    return this.http.post(`${this._base}/v1/annual_payment_option/registration_completed`, { 'annual_user_payout_ids': paymentIds });
  }

  patchUser(data) {
    return this.http.patch(`${this._base}/v1/user`, data);
  }

  getUser() {
    return this.http.get(`${this._base}/v1/user`);
  }

  _uploadProgress = [];
  uploadProgress$: ReplaySubject<any> = new ReplaySubject(1);

  upload(data) {
    const vehicleId = this.auth.lastVehicleId;
    if (vehicleId) {
      data.append("vehicle_id", vehicleId);
    }
    const sum = this._uploadProgress.reduce((partialSum, a) => partialSum + a, 0);
    if (sum / this._uploadProgress.length === 100) {
      this._uploadProgress = [];
    }

    this._uploadProgress.push(0);
    const i = this._uploadProgress.length - 1;
    return this.http.post(`${this._base}/v1/vehicle/images`, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(tap(resp => {
      if (resp.type === HttpEventType.UploadProgress) {
        const percentDone = Math.round(100 * resp.loaded / resp.total);
        this._uploadProgress[i] = percentDone;
        this.uploadProgress$.next(this._uploadProgress);
      } else if (resp.type === HttpEventType.Response) {
        this._uploadProgress[i] = 100;
      }
    }), filter(resp => {
      return resp.type === HttpEventType.Response;
    }));
  }

  userUpload(data) {
    const sum = this._uploadProgress.reduce((partialSum, a) => partialSum + a, 0);
    if (sum / this._uploadProgress.length === 100) {
      this._uploadProgress = [];
    }

    this._uploadProgress.push(0);
    const i = this._uploadProgress.length - 1;
    return this.http.post(`${this._base}/v1/images`, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(tap(resp => {
      if (resp.type === HttpEventType.UploadProgress) {
        const percentDone = Math.round(100 * resp.loaded / resp.total);
        this._uploadProgress[i] = percentDone;
        this.uploadProgress$.next(this._uploadProgress);
      } else if (resp.type === HttpEventType.Response) {
        this._uploadProgress[i] = 100;
      }
    }), filter(resp => {
      return resp.type === HttpEventType.Response;
    }));
  }

  userprofile = null;
  profile() {
    if (this.userprofile) {
      return of(this.userprofile);
    }
    return this.http.get(`${this._base}/v1/profile`);
  }


  download(vid, id) {
    return this.http.get(`${this._base}/v1/vehicle/${vid}/images/${id}`, { responseType: 'blob' });
  }

  vehicles() {
    return this.http.get(`${this._base}/v1/vehicles`);
  }

  status() {
    return this.http.get(`${this._base}/v1/def_vehicle_state`);
  }

  contact(data: any) {
    return this.http.post(`${this._base}/v1/contact_email`, data);
  }

  getRefPayout() {
    return this.http.get(`${this._base}/v1/referral/payout/pending`);
  }

  getChargePayout() {
    return this.http.get(`${this._base}/v1/charging/payout/pending`);
  }

  finalizePayout(tree_counter: number, annual_user_payouts: number[], renew: boolean) {
    return this.http.post(`${this._base}/v1/annual_payment_option/confirm`, {
      tree_counter,
      annual_user_payouts,
      renew,
    });
  }

  finalizeChargePayout(tree_counter: number, charge_ids: number[]) {
    return this.http.post(`${this._base}/v1/charging/confirm`, {
      //tree_counter,
      charges: charge_ids
    });
  }

  confirmReferralPayout(annual_user_payouts: number[]) {
    return this.http.post(`${this._base}/v1/referral/payout/confirm`, {
      annual_user_payouts,
    });
  }

  _patchAddress = false;
  setAddress(address: any) {
    let fn = this.http.post.bind(this.http);
    if (this._patchAddress) {
      fn = this.http.patch.bind(this.http);
    }

    address.country = "";
    Object.keys(address).forEach(key => {
      if (!address[key]) {
        address[key] = null;
      }
    });

    return fn(`${this._base}/v1/address`, address);
  }

  revoke = (cb = () => { }) => { };

  registerComplete() {
    this.sendRegistrationComplete([this.annualPaymentId]).subscribe(() => {
    }, () => { })
  }

  getAddress() {
    const $res = new Subject();
    this.http.get(`${this._base}/v1/address`).subscribe((data: any) => {
      if (!(data?.zip_code === undefined
        && data?.street === undefined
        && data?.city === undefined
        && data?.street_no === undefined)) {
        this._patchAddress = true;
      }
      $res.next(data);
    }, () => {
      $res.next(null);
    });

    return $res;
  }

  holder(data) {
    const vehicleId = this.auth.lastVehicleId;
    return this.http.post(`${this._base}/v1/vehicle/${vehicleId}/holder`, {
      company: data.company,
      first_name: data.first_name,
      surname: data.surname
    });
  }

  _annualPaymentId: any = null;
  get annualPaymentId() {
    return this._annualPaymentId;
  }

  deleteAnnual(cb = null) {
    if (this._annualPaymentId) {
      this.http.delete(`${this._base}/v1/annual_payment_option/${this._annualPaymentId}`).subscribe((res: any) => {
        this._annualPaymentId = null;
        cb?.(res);
      });
    }
  }

  createBenefit(benefitData: any = {}, referralCode: string = null, result$ = null, vehicle_id = null) {
    if (result$ === null) {
      result$ = new Subject();
    }

    if (!vehicle_id) {
      vehicle_id = this.auth.lastVehicleId;
    }

    if (this.newVehicleOnNextRequest) {
      this.newVehicle().subscribe((data: any) => {
        this.newVehicleOnNextRequest = false;
        this.createBenefit(benefitData, referralCode, result$, data?.id || vehicle_id);
      });
      return result$
    }

    const benefit = {
      payout_id: benefitData?.option,
      referral_code: referralCode
    }

    this.http.post(`${this._base}/v1/vehicle/${vehicle_id}/annual`, benefit).subscribe((res: any) => {
      this._annualPaymentId = res?.id || null;

      this.revoke = (cb = () => { }) => {
        if (!(res.id && res.vehicle_id)) {
          return;
        }

        this.sendRevoke(res.vehicle_id, res.id).subscribe(data => {
          cb();
        });
      }
      result$.next(res);
    }, err => {
      result$.next({ error: true });
    });
    return result$;
  }

  createBank(bankData: any = {}) {
    const result$ = new Subject();
    this.http.post(`${this._base}/v1/payment_account`, bankData).subscribe(res => {
      this.hasBank = true;
      result$.next(res);
    }, err => {
      result$.next({ error: true });
    });
    return result$;
  }

  updateBank(bankData: any = {}) {
    if (!this.hasBank) {
      return this.createBank(bankData);
    }

    const result$ = new Subject();
    this.http.patch(`${this._base}/v1/payment_account`, bankData).subscribe(res => {
      this.hasBank = true;
      result$.next(res);
    }, err => {
      //////////console.log("I am here");
      result$.next({ error: true });
    });
    return result$;
  }

  _bankSub: ReplaySubject<any> = new ReplaySubject(1);
  getBank() {
    this.http.get(`${this._base}/v1/payment_account`).subscribe(res => {
      this._bankSub.next(res);
    });

    return this._bankSub;
  }

  getBenefits(affiliateCode) {
    let params = new HttpParams();
    if (affiliateCode) {
      params = params.set("affiliate_code", affiliateCode);
    }

    return this.http.get(`${this._base}/v1/def_payout_options`, { params: params });
  }

  getAffiliateDetails(affiliateCode) {
    return this.http.get(`${this._base}/v1/affiliate_details/${affiliateCode}`);
  }

  // CHARGING STATION
  get charging() {
    return {
      setOperator: (data: any) => {
        return this.http.post(`${this._base}/v1/charging/operator`, data);
      },
      getOperators: () => {
        return this.http.get(`${this._base}/v1/charging/operators`);
      },
      setAddress: (data: any) => {
        return this.http.post(`${this._base}/v1/charging/address`, data);
      },
      getAddresses: () => {
        return this.http.get(`${this._base}/v1/charging/addresses`);
      },
      setPoint: (data: any) => {
        return this.http.post(`${this._base}/v1/charging/point`, data);
      },
      upDatePoint: (id: any, data: any) => {
        return this.http.patch(`${this._base}/v1/charging/point/${id}`, data);
      },
      updatePointFile: (id: any, data: any) => {
        return this.http.post(`${this._base}/v1/charging/point/${id}/upload`, data);
      },
      setCharge: (pointId: any, data) => {
        return this.http.post(`${this._base}/v1/charging/point/${pointId}/charge`, data);
      },
      updateCharge: (chargeId: any, data) => {
        return this.http.patch(`${this._base}/v1/charging/charge/${chargeId}`, data);
      },
      updateChargeFile: (chargeId: any, data) => {
        return this.http.post(`${this._base}/v1/charging/charge/${chargeId}/upload`, data);
      },
      getPoints: () => {
        return this.http.get(`${this._base}/v1/charging/points`);
      },
      getCharges: () => {
        return this.http.get(`${this._base}/v1/charging/charges`);
      },
      getPayoutOptions: () => {
        return this.http.get(`${this._base}/v1/charging/def_payout_options`);
      }
    }
  }
}
