import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormService } from '../../services/form.service';
import { RestService } from '../../services/rest.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {

  @Input() heading: string = "Auszahlungsdaten";

  _disabled: boolean = true
  @Input() set disabled(d: boolean) {
    this._disabled = d;
    /*this.bankname.setValue("");
    this.name.setValue("");
    this.iban.setValue("");*/
  }

  get disabled() {
    return this._disabled;
  }

  @Input() optionalHint = true;
  @Input() loadData: boolean = false;

  @Output() change: EventEmitter<any> = new EventEmitter();

  subs: Subscription[] = [];

  bankForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    bankname: new FormControl("", [Validators.required]),
    iban: new FormControl("", [Validators.required, this.formDataService.IBANValidator()])
  });

  _bankTouched = false;
  get bankTouched() {
    return this._bankTouched;
  }

  set bankTouched(b: boolean) {
    this._bankTouched = b;
    this.change.emit();
  }

  get name() {
    return this.bankForm.get('name');
  }

  get bankname() {
    return this.bankForm.get('bankname');
  }

  get iban() {
    return this.bankForm.get('iban');
  }

  constructor(
    private formDataService: FormService,
    private rest: RestService
  ) { }

  ngOnInit(): void {
    this.subs.push(this.iban
      .valueChanges
      .subscribe((next: any) => {
        this.formDataService.answerBankInfo(
          this.formDataService.bankData.name,
          this.formDataService.bankData.bank,
          next
        );
        this.bankTouched = true;
      }));

    this.subs.push(this.name
      .valueChanges
      .subscribe((next: any) => {
        this.formDataService.answerBankInfo(
          next,
          this.formDataService.bankData.bank,
          this.formDataService.bankData.iban
        );
        this.bankTouched = true;
      }));

    this.subs.push(this.bankname
      .valueChanges
      .subscribe((next: any) => {
        this.formDataService.answerBankInfo(
          this.formDataService.bankData.name,
          next,
          this.formDataService.bankData.iban
        );
        this.bankTouched = true;
      }));

    if (this.loadData) {
      this.subs.push(this.rest.getBank().subscribe((data: any[]) => {
        if (!data) {
          this.rest.hasBank = false;
        }
        const bank = data && data[0] || {};
        //////console.log("Bank loaded, ", bank);
        this.bankname.setValue(bank.bank || "");
        this.iban.setValue(bank.iban || "");
        this.name.setValue(bank.name || "");

        this.formDataService.bankData = bank;
        this.bankTouched = true;
      }));
    }
  }

  get allEmpty() {
    return !(this.bankname.value || this.name.value || this.iban.value)
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
