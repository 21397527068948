import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExternalScriptService {

  loadedScripts: any = {};
  loadedCss: any = {};

  constructor() { }

  loadExternalCss(url: string) {
    if(this.loadedCss[url]) {
      return Promise.resolve();
    }

    return new Promise(resolve => {
      this.loadedCss[url] = true;
      var head  = document.getElementsByTagName('head')[0];
      var link  = document.createElement('link');
      link.rel  = 'stylesheet';
      link.type = 'text/css';
      link.href = url;
      link.media = 'all';
      link.onload = resolve;
      head.appendChild(link);
    });
  }

  loadExternalScript(url: string) {
    if(this.loadedScripts[url]) {
      return Promise.resolve();
    }

    return new Promise(resolve => {
      this.loadedScripts[url] = true;
      const body = <HTMLDivElement>document.body;
      const script = document.createElement('script');
      script.innerHTML = '';
      script.src = url;
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      body.appendChild(script);
    });
  }
}
