import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutThgComponent } from './about-thg/about-thg.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { HomeComponent } from './home/home.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'login',
        component: HomeComponent,
      },
      {
        path: 'reset',
        component: HomeComponent,
      }
    ]
  },
  {
    path: 'about-thg',
    component: AboutThgComponent,
    data: {
      title: 'THG Page'
    }
  },
  {
    path: 'datenschutz',
    component: DatenschutzComponent,
    data: {
      title: 'Datenschutz Page'
    }
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class EvCashbackRoutingModule {}
