import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { API as SentryCore_API } from "@sentry/core";
import { urlEncode as SentryUtils_urlEncode } from "@sentry/utils";
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

if (environment.sentry_dsn) {
  // We change the _encodedAuth prototype to rename sentry_key/sentry_version parameters to avoid adblocker from blocking the requests
  // @ts-ignore
  SentryCore_API.prototype._encodedAuth = function () {
    var dsn = this.getDsn();
    var auth = {
      key: dsn.publicKey, // use just key instead of sentry_key
      version: 7, // use just version instead of sentry_version
    };
    return SentryUtils_urlEncode(auth);
  };

  Sentry.init({
    dsn: environment.sentry_dsn,
    debug: environment.sentry_debug,
    attachStacktrace: true,
    normalizeDepth: 4,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [environment.AUTH_URL, environment.BACK_URL],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  useJit: true,
  preserveWhitespaces: true
})
  .catch(err => {
    //////////console.log(err)
  });
