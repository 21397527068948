import { Component, OnInit } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'app-affiliate-legal',
  templateUrl: './affiliate.component.html',
  styleUrls: ['./affiliate.component.scss']
})
export class AffiliateComponent implements OnInit {
  env = environment;

  constructor() { }

  ngOnInit(): void {
  }

}
