import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'app-impressum-legal',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {
  @Input() show = true;

  env = environment;

  constructor() { }

  ngOnInit(): void {
  }

}
