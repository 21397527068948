import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';
import { AuthService } from './services/auth.service';
import { Subscription } from 'rxjs';

import * as AOS from 'aos';

import { AffiliateService } from './services/affiliate.service';
import { AnalyticsService } from './services/google/analytics.service';
import { environment } from '@env';
import { ChatService } from './services/chat.service';

declare var $: any;

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: `
    <app-notification></app-notification>
    <router-outlet></router-outlet>

    <app-modals [id]="'affiliateModal'">
        <h4 header>Unsere Affiliate und B2B Programme</h4>
        <app-affiliate-legal body></app-affiliate-legal>
    </app-modals>

    <app-modals [id]="'affiliatePopupModal'">
        <h4 header>Ihre Sonderkonditionen</h4>
        <div body>
          <div style="width: 50%; float: left">
            <img src="/assets/img/affiliatepopup.png" style="width: 90%" />
          </div>
          <div style="width: 50%; float: right">
            {{affiliateIntro}}
          </div>
        </div>
    </app-modals>

    <app-modals [id]="'impressumModal'">
        <h4 header>Impressum</h4>
        <app-custom-container body [id]="'impressum'">
          <ng-container default>
            <app-impressum-legal [show]="false"></app-impressum-legal>
          </ng-container>
        </app-custom-container>
    </app-modals>

    <app-modals [id]="'agbModal'">
      <h4 header>AGBs</h4>
      <app-custom-container body [id]="'agb'">
        <ng-container default>
          <app-agb-legal [show]="false"></app-agb-legal>
        </ng-container>
      </app-custom-container>
    </app-modals>

    <app-modals [id]="'widerrufModal'">
      <h4 header>Widerrufsbelehrung</h4>
      <app-custom-container body [id]="'widerruf'">
        <ng-container default>
          <app-widerruf></app-widerruf>
        </ng-container>
      </app-custom-container>
    </app-modals>

    <app-modals [id]="'datenschutzModal'">
      <h4 header>Datenschutzerklärung</h4>
      <app-custom-container body [id]="'datenschutz'">
        <ng-container default>
          <app-datenschutz-legal [show]="false"></app-datenschutz-legal>
        </ng-container>
      </app-custom-container>
    </app-modals>

    <app-modals [id]="'marketingModal'">
        <h4 header>Marketingeinwilligung</h4>
        <app-custom-container body [id]="'marketing'">
          <ng-container default>
            <p>
              Ich bin damit einverstanden, dass die NYNEX satellite OHG meinen Namen, E-Mail, Fahrzeugtyp, Fahrzeugmarke für
              Betreuung und Werbung verwendet. Dies bezieht sich auf alle Produkte/Dienstleistungen, die NYNEX satellite OHG
              jeweils anbietet/vermittelt (insb. Ankaufs, Versicherungs- und Leasingverträge, etc.). Die Einwilligung ist nicht
              Voraussetzung für die Inanspruchnahme der Leistungen und jederzeit (ohne Angabe von Gründen) schriftlich per Post
              oder per E-Mail widerrufbar.
            </p>
          </ng-container>
        </app-custom-container>
    </app-modals>

    <app-modals [id]="'cModal'">
        <h4 header>Datenschutzeinstellungen</h4>
        <ng-container body>
          <p>Ihre Zufriedenheit ist unser Ziel, deshalb verwenden wir die Analyse-Werkzeuge von Google und Meta. Diese sammeln Daten über aufgerufene Seiten und geklickte Buttons.</p>
          <p>Mit Klick auf „Zustimmen“ erklären Sie sich mit der Verwendung dieser Dienste einverstanden. Ihre Einwilligung können Sie jederzeit mit Wirkung auf die Zukunft widerrufen oder ändern.</p>
        </ng-container>
        <button footer type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="loadAnalytics()">Zustimmen</button>
    </app-modals>

    <div [style]="cookiesVisible ? 'display: none' : ''">
        <app-modals [id]="'loginModal'">
          <h4 header>Anmelden</h4>
          <ng-container body>
              <app-login></app-login>
          </ng-container>
        </app-modals>

        <app-modals [id]="'pwresetModal'">
          <h4 header>Passwort setzen</h4>
          <ng-container body>
              <app-password-reset></app-password-reset>
          </ng-container>
        </app-modals>
    </div>
  `,
  providers: [IconSetService],
})
export class CoreComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  constructor(
    private router: Router,
    public iconSet: IconSetService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private affiliateService: AffiliateService,
    private analytics: AnalyticsService,
    //Don't remove this!!!
    private _: ChatService
  ) {
    // iconSet singleton
    iconSet.icons = { ...freeSet };
  }

  get affiliateIntro() {
    return this.affiliateService.introText;
  }

  refreshTo = null;

  loadAnalytics() {
    this.analytics.start();
  }

  cookiesVisible = false;

  ngOnInit() {
    AOS.init();

    if (!this.analytics.canAnalyze && environment.GOOGLE_ANALYTICS_PROPERTY) {
      this.cookiesVisible = true;
      setTimeout(() => {
        $('#cModal').on('hide.bs.modal', () => {
          this.cookiesVisible = false;
          this.cdref.detectChanges();
        });
        
        $('#cModal').modal('show');
      }, 500);
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.route.queryParams
      .subscribe(params => {
        const { a, r } = params;

        if (a) {
          this.affiliateService.setAffiliate(a);
        }

        this.affiliateService.setReferral(r);
      });

    this.subs.push(this.auth.onLoginChange.subscribe(jwt => {
      if (this.auth.isAuthenticated()) {
        clearTimeout(this.refreshTo);
        let timeOutMs = (this.auth.expiresInSeconds - 10) * 1000;
        if (timeOutMs - 2147483647 > 0) {
          timeOutMs = 2147483600;
        }

        this.refreshTo = setTimeout(() => {
          this.auth.refresh();
        }, timeOutMs);

        return;
      }

      clearTimeout(this.refreshTo);

      //this.router.navigate(["home"]);
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
