import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ClientService } from '../../../services/client.service';
import { ImageService } from '../../../services/image.service';
import { RestService } from '../../../services/rest.service';
import { WizardService } from '../../../services/wizard.service';
import { FileUploadComponent } from '../../file-upload/file-upload.component';

@Component({
  selector: 'app-multi-client',
  templateUrl: './multi-client.component.html',
  styleUrls: ['./multi-client.component.scss']
})
export class MultiClientComponent implements OnInit {

  @ViewChild("upload", {static: false})
  public upload: ElementRef<FileUploadComponent>;

  @Output() done: EventEmitter<boolean> = new EventEmitter();

  files = [];

  constructor(
    private imageService: ImageService,
    private rest: RestService,
    private wizardService: WizardService,
    private clientService: ClientService
  ) { }

  ngOnInit(): void {
  }

  onData(data: any[]) {
    this.files = data.map(d => {
      return { data: d.data, name: d.name}
    });
  }

  checkDone(wantedImages, doneImages) {
    if(wantedImages === doneImages) {
      (this.upload as any).reset();
      this.done.emit(true);
      this.loading = false;
      return;
    }
  }

  loading: boolean = false;

  prev() {
    if(this.loading) {
      return;
    }

    this.wizardService.prev();
  }

  uploadAll() {
    if(this.loading) {
      return;
    }

    this.loading = true;
    const wantedImages = this.files.length;
    let doneImages = 0;
    this.files.forEach(file => {
        let formData = new FormData();
        formData.append("image", this.imageService.base64ToBlob(file.data), file.name);
        formData.append("side", "fleet");
    
        this.rest.upload(formData).subscribe(_ => {
          doneImages++;
          this.checkDone(wantedImages, doneImages);
        }, _ => {
          doneImages++;
          this.checkDone(wantedImages, doneImages);
        });
    });
    this.rest.registerComplete();
  }

  switch() {
    this.clientService.switchType();
  }
}
