import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RenewService {

  renewData$: ReplaySubject<any> = new ReplaySubject(1); 

  constructor() { }

  renewData(rd: any) {
    this.renewData$.next(rd);
  }
}
