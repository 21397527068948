import { Component, OnInit } from '@angular/core';
import { CmsReaderService, CmsService } from '@autocash-core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-agb',
  templateUrl: './agb.component.html',
  styleUrls: ['./agb.component.scss']
})
export class AgbComponent implements OnInit {
  template: string = "";

  constructor(
    private cmsService: CmsReaderService
  ) { }

  ngOnInit(): void {
    this.cmsService.afterInit.subscribe(_ => {
      const html = this.cmsService.getCustomPageData("agb").html || ""
      this.template = html;
    });
  }

}
