import { Component, OnInit } from '@angular/core';
import { CmsReaderService, CmsService } from '@autocash-core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss']
})
export class MarketingComponent implements OnInit {
  template: string = "";

  constructor(
    private cmsService: CmsReaderService
  ) { }

  ngOnInit(): void {
    this.cmsService.afterInit.subscribe(_ => {
      const html = this.cmsService.getCustomPageData("marketing").html || ""
      this.template = html;
    });
  }
}
