import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ClientService, CmsReaderService, CmsService } from '@autocash-core';
import { environment } from '@env';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  subs: Subscription[] = [];
  
  bannerHeader: string = "";
  bannerText: string = "";
  template: string = "";

  env = environment;

  constructor(
    private clientService: ClientService,
    private cmsService: CmsReaderService
  ) { }

  scrollTo(id: string) {
    $(id)[0]?.scrollIntoView();
  }

  ngOnInit(): void {
    this.clientService.setType("single");
    this.cmsService.afterInit.subscribe(_ => {
      const homeData = this.cmsService.getCustomPageData("home");
      this.bannerHeader = homeData?.banner?.header || "";
      this.bannerText = homeData?.banner?.text || "";
      const html = (this.cmsService.getCustomPageData("home").html || "")
                    .replace(/<div id="appRegister"[^\/]*\/div[>]/i, "<app-signup-workflow></app-signup-workflow>")
                    .replace(/<div id="appContact"[^\/]*\/div[>]/i, "<app-contact-form></app-contact-form>")
                    .replace(/<div id="appFooter"[^\/]*\/div[>]/i, "<app-contact-form></app-contact-form>")
      this.template = html;
    });

    if(window?.location?.pathname?.includes("home/login")) {
      $('#loginModal').modal('show');
    } else if(window?.location?.pathname?.includes("home/reset")) {
      $('#pwresetModal').modal('show');
    }
  }
}
