import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutThgComponent } from './about-thg/about-thg.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { B2bComponent } from './b2b/b2b.component';
import { ChargingStationComponent } from './charging-station/charging-station.component';
import { FleetComponent } from './fleet/fleet.component';
import { HomeComponent } from './home/home.component';
import { TreepageComponent } from './treepage/treepage.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'login',
        component: HomeComponent,
      },
      {
        path: 'reset',
        component: HomeComponent,
      }
    ]
  },
  {
    path: 'fleet',
    component: FleetComponent,
    data: {
      title: 'Flottenkunden'
    }
  },
  {
    path: 'climate',
    component: TreepageComponent,
    data: {
      title: 'Klimahelden'
    }
  },
  {
    path: 'b2b',
    component: B2bComponent,
    data: {
      title: 'B2B Partner'
    }
  },
  {
    path: 'charge',
    component: ChargingStationComponent,
    data: {
      title: 'Ladeinfrastrukturbetreiber'
    }
  },
  {
    path: 'about-thg',
    component: AboutThgComponent,
    data: {
      title: 'Über THG'
    }
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    data: {
      title: 'Über uns'
    }
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class EAutoCashRoutingModule {}
