import { Injectable } from '@angular/core';
import { environment } from '@env';
import { AuthService } from './auth.service';
import { AffiliateService } from './affiliate.service';
import { ExternalScriptService } from './external-script.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(
    private auth: AuthService,
    private externalScript: ExternalScriptService,
    private affiliateService: AffiliateService,
  ) {
    setTimeout(() => {
      if (environment.CHATWOOT_ENABLED && affiliateService.config?.affiliate_company?.chat_enabled != false) {
        this.initChat();
      }
    }, 0);
  }

  initChat() {
    (window as any).chatwootSettings = environment.CHATWOOT_SETTINGS;

    const onUserChange = this.auth.onUserChange;
    window.addEventListener("chatwoot:ready", function () {
      onUserChange.subscribe(user => {
        //////console.log("chat onUserChange", user)
        const chatwoot = (window as any).$chatwoot

        if (chatwoot) {
          const is_admin_login = user?.is_admin_login || false;
          if (is_admin_login) {
            ////console.log("chatwoot init skipped (is_admin_login)", user);
            return;
          }

          let chatwoot_identifier = user?.chatwoot_identifier || user?.email || null;
          if (!chatwoot_identifier && user?.id)
            chatwoot_identifier = `${environment.PROJECT} User ID ${user?.id}`;
          else if (!chatwoot_identifier)
            chatwoot_identifier = `${environment.PROJECT} ${Math.random().toString(36).slice(2, 10)}`;
          let options = {
            name: chatwoot_identifier,
            avatar_url: "",
            email: user?.email,
            identifier_hash: user?.chatwoot_identifier_hash,
          };
          //////console.log("chat user setUser", user, chatwoot_identifier, options)
          chatwoot.setUser(chatwoot_identifier, options);
          if (user?.id) {
            chatwoot.setCustomAttributes({
              user_link: `${environment.ADMIN_URL}/admin/user/edit/?id=${user?.id}`,
              vehicles_link: `${environment.ADMIN_URL}/admin/vehicle/?flt1_user_user_id_equals=${user?.id}`,
              user_id: user?.id,
            });
          }
        }
      });
    });

    this.externalScript.loadExternalScript("https://chatwoot.nynex.de/packs/js/sdk.js").then(() => {
      (window as any).chatwootSDK.run(environment.CHATWOOT_RUN)
    });
  }
}
