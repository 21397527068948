import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-about-thg',
  templateUrl: './about-thg.component.html',
  styleUrls: ['./about-thg.component.scss']
})
export class AboutThgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  scrollTo(id: string) {
    $(id)[0]?.scrollIntoView();
  }
}
